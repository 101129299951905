import {observable, computed} from 'mobx';
import moment from 'moment-timezone';

class SchedulerStore {
  private open = '8:00 AM'
  private close = '4:00 PM'

  private openHour = moment.tz(this.open, ['h:mm A'], 'America/Chicago').get('hour');
  private closeHour = moment.tz(this.close, ['h:mm A'], 'America/Chicago').get('hour');

  @computed public get businessDays(): number[] {
    const allDays = [...Array(moment(this.dateViewing).daysInMonth()).keys()].map((_, i): number => {
      return moment(this.dateViewing).date(i + 1).utc().format();
    });
    const availableBusinessDays = allDays.filter((day): boolean => {
      const dayOfWeekCST = moment(day).tz('America/Chicago').format('e');
      return (dayOfWeekCST !== '0' && dayOfWeekCST !== '6' && moment(day).isSameOrAfter(moment(), 'day'));
    });
    return availableBusinessDays;
  }

  @computed public get monthlyHours(): string[] {
    const allHours: string[] = [];
    this.businessDays.forEach((day): void => {
      const dayCST = moment.tz(day, 'America/Chicago');
      for (let i = this.openHour; i < this.closeHour; i++) { // available hours start 1 hour from now
        if (!(moment(day).tz('America/Chicago').format('e') === '1' && i === this.openHour)) { // skip 8AM Mondays
          dayCST.clone().hour(i).minutes(0).isSameOrAfter(moment.tz('America/Chicago').add(1, 'hours'), 'minute')
        && allHours.push(dayCST.clone().hour(i).minutes(0).utc().format());
        }
        dayCST.clone().hour(i).minutes(30).isSameOrAfter(moment.tz('America/Chicago').add(1, 'hours'), 'minute')
        && allHours.push(dayCST.clone().hour(i).minutes(30).utc().format());
      }
    });
    return allHours;
  }

  @computed public get todaysHours(): string[] {
    return this.monthlyHours.filter((timeslot): string => {
      return moment(timeslot).isSame(moment(this.selectedDay), 'day');
    });
  }

  @observable public dateViewing: object;
  @observable public selectedDay: object;
  @observable public selectedTime: string | undefined;
  @observable public showContactForm: boolean = false;
  @observable public submitSuccess: boolean = false;
}

const schedulerStore = new SchedulerStore();
export default schedulerStore;
