import * as React from 'react';
import {action} from 'mobx';
import {observer} from 'mobx-react';

import LoadingPage from 'components/LoadingPage';
import Page404 from 'components/page/error/404Page';
import WordpressPage from 'components/page/wordpress/WordpressPage';
import AppStore from 'stores/app';
import LangStore from 'stores/lang';

@observer
class WordpressPageResolver extends React.Component {
  public state: {
    hasFinished: boolean;
  }
  public props: {
    appStore: AppStore;
    langStore: LangStore;
    slug: string;
    children?: React.ReactNode;
  }
  constructor(props) {
    super(props);
    this.state = {
      hasFinished: false,
    };
  }
  @action
  async initData() {
    this.props.appStore.pageWordpressPage.page = false;
    this.props.appStore.pageWordpressPage.page = await apiFetch(
      this.props.appStore.req,
      `wordpress/page/${this.props.slug}`,
    );
    this.setState({
      hasFinished: true,
    });
  }
  componentDidMount() {
    this.initData();
    this.setState({
      hasFinished: false,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.hasFinished) {
      this.setState({
        hasFinished: false,
      });
    }
  }
  render() {
    if (
      this.props.appStore.pageWordpressPage.page !== false &&
      !Object.keys(this.props.appStore.pageWordpressPage.page).length
    ) {
      return (
        <Page404
          appStore={this.props.appStore}
        />
      );
    }
    if (this.props.appStore.pageWordpressPage.page) {
      return (
        <WordpressPage
          appStore={this.props.appStore}
          langStore={this.props.langStore}
          slug={this.props.slug}
        />
      );
    }
    if (!this.state.hasFinished) {
      return <LoadingPage/>;
    }
    return null;
  }
}

export default WordpressPageResolver;
