import {observable, autorun} from 'mobx';

import {Map} from 'immutable';

import PageStaff from 'stores/page-staff';
import PageProjects from 'stores/page-projects';
import PageNews from 'stores/page-news';
import PageTimeline from 'stores/page-timeline';
import PageIndex from 'stores/page-index';
import PageWordpressPage from 'stores/page-wordpress-page';
import PageFAQStore from 'stores/page-faq';
import PageNewsletter from 'stores/page-newsletter';
import PageEvents from 'stores/page-events';
import PageCareers from 'stores/page-careers';

import ComponentTestimonial from 'stores/component-testimonial';
import ComponentStaffCollection from 'stores/component-staff-collection';

class AppStore {
  @observable req: any;
  @observable origin: any;
  @observable isoCode: any;
  @observable pageStaff = new PageStaff();
  @observable pageProjects = new PageProjects();
  @observable pageNews = new PageNews();
  @observable pageTimeline = new PageTimeline();
  @observable pageIndex = new PageIndex();
  @observable pageWordpressPage = new PageWordpressPage();
  @observable pageFAQ = new PageFAQStore();
  @observable pageNewsletter = new PageNewsletter();
  @observable pageCareers = new PageCareers();
  @observable pageEvents = new PageEvents();
  @observable loggedIn = false;

  @observable componentStaffCollection = new ComponentStaffCollection();
  @observable componentTestimonial = new ComponentTestimonial();

  @observable cookies: Map<{}, {}> = Map();

  @observable currentTranslatorLanguage = 'es';
  @observable currentTranslatorKey = '';
  @observable currentTranslatorValue = '';
  @observable currentTranslatorReset = '';
  @observable currentTranslatorOpen = false;
  @observable currentTranslatorLeft = false;

  responsiveSizes = {
    'xs': 0,
    'sm': 544,
    'md': 768,
    'lg': 992,
    'xl': 1200,
  };

  @observable location: false | Location = false;

  @observable webp;

  @observable is404 = false;

  @observable headerBottom = 0;

  @observable public showSchedulerModal = false;
  @observable.ref public appRef;

  private cookieDough;

  initCookieDough(cookieDough) {
    this.cookieDough = cookieDough;
    this.cookies = Map(this.cookieDough.all());
    autorun(() => {
      this.cookies.forEach((value, key) => {
        const maxAge = key !== '506C_VALID_VIEWER' ? 60 * 60 * 24 * 365 : 60 * 60 * 24;
        this.cookieDough.set(
          key,
          value,
          {path: '/', maxAge: maxAge}
        );
      });
    });
  }
}

export default AppStore;
