import * as React from 'react';
import uniq from 'lodash/uniqBy';
import reactTreeWalker from 'react-tree-walker';

import {IContactFormInput} from 'lib/IContactFormInput';
import styles from 'components/stylesheets/styles.scss';
import LangStore from 'stores/lang';
import AppStore from 'stores/app';
import SchedulerStore from 'stores/scheduler-store';
import {log} from 'lib/log';
import FormInput from './ContactFormInput';

interface IContactFormState extends IContactFormInput {
  submitting: boolean;
  errors: string[];
  triedSubmit: boolean;
  success: boolean;
  captcha?: string;
  captchaKey?: string;
}

class SchedulerForm extends React.Component {
  public props: Readonly<{
    appStore: AppStore;
    langStore: LangStore;
    defaults?: IContactFormState;
    children?: React.ReactNode;
    localTime: string;
    cstTime: string;
  }>
  public state: IContactFormState;

  public constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      errors: [],
      triedSubmit: false,
      success: false,
      captcha: undefined,
      captchaKey: undefined,
    };
    this.submit = this.submit.bind(this);
    this.childAssign = this.childAssign.bind(this);
    this.getForm = this.getForm.bind(this);

    let qs: any = {};
    if (typeof location != 'undefined') {
      // qs = location.search;
      // TODO: Probably not needed, qs on javascript version
    } else {
      qs = this.props.appStore.req.query;
    }
    this.state.errors = qs.errors;
    if (qs.errors && qs.errors.length > 0) {
      this.state.triedSubmit = true;
    }
    Object.assign(this.state, qs.state);
    Object.assign(this.state, this.props.defaults || {});
  }

  private getForm(): JSX.Element {
    const disableAll = this.state.submitting || this.state.success;
    return (
      <form
        className={styles.contactForm}
        method='post'
        action='/api/contact/schedule'
      >
        <script src='https://www.googletagmanager.com/gtag/js?id=AW-1029528092'></script>
        <script
          dangerouslySetInnerHTML={{__html:
            `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-1029528092');
            `.replace(/ +/g, ' '),
          }}
          suppressHydrationWarning
        />
        <h2 className={styles.contactSection}>
          {this.props.langStore.lang['CONTACT_FORM_PERSONAL_INFO']}
        </h2>
        <div className={styles.muiRow}>
          <FormInput
            langStore={this.props.langStore}
            width={2}
            name='FIRST_NAME'
            latinOnly
            errors={this.state.errors}
            assign={this.childAssign}
            triedSubmit={this.state.triedSubmit}
            appStore={this.props.appStore}
            value={this.state.FIRST_NAME}
            autocomplete='given-name'
            disabled={disableAll}
          />
          <FormInput
            langStore={this.props.langStore}
            width={2}
            name='LAST_NAME'
            latinOnly
            errors={this.state.errors}
            assign={this.childAssign}
            triedSubmit={this.state.triedSubmit}
            appStore={this.props.appStore}
            value={this.state.LAST_NAME}
            autocomplete='family-name'
            disabled={disableAll}
          />
        </div>
        <div className={styles.muiRow}>
          <FormInput
            langStore={this.props.langStore}
            width={1}
            name='PHONE_NUMBER'
            placeholder='+22 607 123 4567'
            errors={this.state.errors}
            assign={this.childAssign}
            triedSubmit={this.state.triedSubmit}
            appStore={this.props.appStore}
            value={this.state.PHONE_NUMBER}
            autocomplete='tel'
            disabled={disableAll}
          />
        </div>
        <div className={styles.muiRow}>
          <FormInput
            langStore={this.props.langStore}
            width={1}
            name='EMAIL'
            placeholder='example@example.com'
            errors={this.state.errors}
            assign={this.childAssign}
            triedSubmit={this.state.triedSubmit}
            appStore={this.props.appStore}
            value={this.state.EMAIL}
            autocomplete='email'
            disabled={disableAll}
          />
        </div>
        <h2 className={styles.contactSection}>
          {this.props.langStore.lang['CONTACT_FORM_LOCATION']}
        </h2>
        <div className={styles.muiRow}>
          <FormInput
            langStore={this.props.langStore}
            width={1}
            name='COUNTRY'
            errors={this.state.errors}
            assign={this.childAssign}
            triedSubmit={this.state.triedSubmit}
            appStore={this.props.appStore}
            value={this.state.COUNTRY}
            autocomplete='country-name'
            disabled={disableAll}
          />
          <FormInput
            langStore={this.props.langStore}
            width={1}
            name='PREFERRED_LANGUAGE'
            errors={this.state.errors}
            assign={this.childAssign}
            triedSubmit={this.state.triedSubmit}
            appStore={this.props.appStore}
            optional
            value={this.state.PREFERRED_LANGUAGE}
            autocomplete='language'
            disabled={disableAll}
          />
        </div>
        {this.state.captcha &&
          <>
            <div
              dangerouslySetInnerHTML={{__html: this.state.captcha}}
            />
            <FormInput
              langStore={this.props.langStore}
              width={1}
              name='CAPTCHA'
              errors={this.state.errors}
              assign={this.childAssign}
              triedSubmit={this.state.triedSubmit}
              appStore={this.props.appStore}
              value={this.state.CAPTCHA}
              autocomplete='off'
              disabled={disableAll}
            />
          </>
        }
        <button
          className={[
            styles.muiBtn,
            styles.muiBtnAccent,
            styles.muiBtnRaised,
            styles.muiBtnLarge,
            styles.contactFormSendButton,
            ...(this.state.success && [styles.contactFormSendButtonSuccess] || []),
          ].join(' ')}
          disabled={this.state.submitting || this.state.success}
          onClick={this.submit}
          type='submit'
        >
          {this.state.success && this.props.langStore
            .lang['CONTACT_FORM_SEND_BUTTON_SUCCESS']}
          {!this.state.success && this.state.submitting && this.props.langStore
            .lang['CONTACT_FORM_SEND_BUTTON_SUBMITTING']}
          {!this.state.success && !this.state.submitting && this.props.langStore
            .lang['CONTACT_FORM_SEND_BUTTON']}
        </button>
      </form>
    );
  }
  public async componentDidMount(): Promise<void> {
    let errors: string[] = [];
    await reactTreeWalker(this.getForm(), (element, instance): void => {
      if (instance && instance.validate) {
        errors = [
          ...errors,
          ...instance.validate(instance.state.value),
        ];
      }
    });
    this.setState({
      errors: errors,
    });
  }
  private async submit(e): Promise<void> {
    e.preventDefault();
    this.setState({
      submitting: true,
      triedSubmit: true,
    });
    const data = await apiFetch(this.props.appStore.req, 'contact/schedule', {
      method: 'POST',
      body: JSON.stringify(Object.assign(
        {},
        this.state,
        {cstTime: this.props.cstTime},
        {localTime: this.props.localTime},
        {pathname: window.location.pathname},
        {javascriptSubmission: true},
      )),
      headers: {
        'Content-Type': 'application/json',
      },
      json: true,
    });

    if (data.success) {
      gtag('event', 'conversion', {
        'send_to': 'AW-1029528092/pTjHCLLblXwQnLT16gM',
        'Consultation': (e): void => {
          log('Contact Form Submitted', e);
        },
      });
      gtag('event', 'conversion', {
        'send_to': 'AW-786286140/5vTaCO3YjNoBELyM9_YC',
        'event_callback': (e): void => {
          log('Consultation Form Submitted Site 2', e);
        },
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        formLocation: 'ConsultationForm',
        event: 'formsubmission',
      });
      setTimeout((): void => {
        this.setState({
          submitting: false,
          success: true,
        });
      }, 5);
      setTimeout((): void => {
        SchedulerStore.submitSuccess = true;
        SchedulerStore.showContactForm = false;
      }, 1000);
      setTimeout((): void => {
        this.props.appStore.showSchedulerModal = false;
      }, 10000);
    } else {
      if (data.captcha) {
        this.setState({
          captcha: data.captcha,
          captchaKey: data.captchaKey,
          submitting: false,
        });
      } else {
        this.setState({
          submitting: false,
          errors: data.errors,
        });
      }
    }
  }
  private childAssign(name, data, errors): void {
    const combinedErrors = uniq([
      ...errors,
      ...this.state.errors.filter((error): boolean => error.indexOf(name) == -1),
    ]);
    this.setState({
      [name]: data,
      errors: combinedErrors,
    });
  }
  public render(): React.ReactNode {
    return this.getForm();
  }
}

export default SchedulerForm;
