import * as React from 'react';
import imageLinkToWebPLink from 'lib/imageLinkToWebPLink';

import Link from 'components/Link';
import styles from 'components/stylesheets/styles.scss';

interface IImageProps {
  appStore: any;
  alt: string;
  linkTo?: string;
  containerClassName?: string;
  noContainerClass?: boolean;
  src?: string;
  onClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
  className?: string;
  width?: string;
}

class Image extends React.Component {
  props: IImageProps;
  render() {
    const props: IImageProps = {appStore: undefined, alt: ''};
    Object.assign(props, this.props);

    delete props.appStore;
    delete props.linkTo;
    delete props.containerClassName;
    delete props.noContainerClass;

    if (this.props.appStore.webp) {
      props.src = imageLinkToWebPLink(props.src);
    }

    const containerClassName: string[] = [];
    if (this.props.containerClassName) {
      containerClassName.push(this.props.containerClassName);
    }
    if (!this.props.noContainerClass) {
      containerClassName.push(styles.imageContainer);
    }
    const combinedContainerClassName = containerClassName.join(' ');

    if (this.props.linkTo) {
      return <Link
        appStore={this.props.appStore}
        to={this.props.linkTo}
        className={combinedContainerClassName}
      >
        <img {...props} alt={props.alt}/>
      </Link>;
    } else {
      return <div className={combinedContainerClassName}>
        <img {...props} alt={props.alt}/>
      </div>;
    }
  }
}

export default Image;
