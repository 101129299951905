import * as React from 'react';
import * as moment from 'moment-timezone';
import autobind from 'autobind';
import {observer} from 'mobx-react';

import styles from 'components/stylesheets/styles.scss';
import SchedulerStore from 'stores/scheduler-store';

export interface CalendarStateInterface {
  allMonths: string[];
  showMonths: boolean;
  showYears: boolean;
}

@observer
class Calendar extends React.Component
  <{}, CalendarStateInterface> {
  public state: CalendarStateInterface = {
    allMonths: moment.months(),
    showMonths: false,
    showYears: false,
  }

  public constructor(props) {
    super(props);
  }

  public componentDidMount(): void {
    SchedulerStore.selectedDay = moment();
    SchedulerStore.dateViewing = moment();
  }

  @autobind
  private weekdayShort(): string[] {
    return moment.weekdaysShort();
  }

  @autobind
  private firstDay(): number {
    return parseInt(moment(SchedulerStore.dateViewing).startOf('month').format('d'));
  }

  @autobind
  public setMonth(e: number): void {
    this.setState({
      showMonths: false,
    });
    SchedulerStore.dateViewing = moment(SchedulerStore.dateViewing).month(e);
    SchedulerStore.selectedDay = moment(SchedulerStore.monthlyHours.find((time): string => {
      return moment(time).isSame(moment(SchedulerStore.dateViewing), 'month');
    }));
    SchedulerStore.selectedTime = undefined;
  }

  @autobind
  public setYear(e: number): void {
    this.setState({
      showYears: false,
      showMonths: true,
    });
    SchedulerStore.dateViewing = moment(SchedulerStore.dateViewing).year(e);
  }

  @autobind
  public incrementMonth(e: number): void {
    SchedulerStore.dateViewing = e > 0 ?
      moment(SchedulerStore.dateViewing).add(1, 'month')
      :
      moment(SchedulerStore.dateViewing).subtract(1, 'month');
    SchedulerStore.selectedDay = moment(SchedulerStore.monthlyHours.find((time): string => {
      return moment(time).isSame(moment(SchedulerStore.dateViewing), 'month');
    }));
    SchedulerStore.selectedTime = undefined;
  }

  @autobind
  public setDay(e: number): void {
    SchedulerStore.selectedDay = moment(SchedulerStore.dateViewing).date(e);
    SchedulerStore.selectedTime = undefined;
  }

  @autobind
  private isToday(e: number): boolean {
    return moment().isSame(moment(SchedulerStore.dateViewing).date(e), 'day');
  }

  @autobind
  private isSelected(e: number): boolean {
    return moment(SchedulerStore.selectedDay)
      .isSame(moment(SchedulerStore.dateViewing).date(e), 'day');
  }

  @autobind
  private isDisabled(e: number): boolean {
    const calendarDay = moment(SchedulerStore.dateViewing).date(e);
    if (calendarDay.isSame(moment(), 'day')) {
      return false;
    }
    if (calendarDay.isBefore(moment(), 'day')) {
      return true;
    }
    return !SchedulerStore.monthlyHours.some((timeslot): boolean => {
      return moment(timeslot).isSame(calendarDay, 'day');
    });
  }

  public render(): React.ReactNode {
    const weekdayShortNames = this.weekdayShort();
    const blankDays: number[] = [...Array(this.firstDay())];
    const daysOfMonth: number[] =
      [...Array(moment(SchedulerStore.dateViewing).daysInMonth()).keys()].map((_, i): number => (i + 1));
    const years = [moment().year(), moment().add(1, 'year').year()];
    return (
      <div className={styles.calendarContainer}>
        <div className={styles.monthPicker}>
          <div className={styles.calendarHeader}>
            {!this.state.showMonths && !this.state.showYears &&
            <button
              className={styles.monthArrow + ' ' + styles.prev}
              onClick={(): void => this.incrementMonth(-1)}
              title='Previous Month'
              disabled={moment(SchedulerStore.dateViewing).isSameOrBefore(moment(), 'month')}
            >
              &lt;
            </button>
            }
            <div className={styles.monthYear}>
              <button
                className={styles.monthTitle}
                onClick={(): void => this.setState({
                  showMonths: !this.state.showMonths,
                  showYears: false,
                })}
                title='Change Month'
              >
                {moment(SchedulerStore.dateViewing).format('MMMM')}
              </button>
              <button
                className={styles.monthTitle}
                onClick={(): void => this.setState({
                  showMonths: false,
                  showYears: !this.state.showYears,
                })}
                title='Change Year'
              >
                {moment(SchedulerStore.dateViewing).format('YYYY')}
              </button>
            </div>
            {!this.state.showMonths && !this.state.showYears &&
            <button
              className={styles.monthArrow + ' ' + styles.next}
              onClick={(): void => this.incrementMonth(1)}
              title='Next Month'
            >
              &gt;
            </button>
            }
          </div>
          <div className={styles.yearGrid}>
            {this.state.showYears && years.map((year): React.ReactElement => {
              return (
                <button
                  key={year}
                  className={styles.yearOption}
                  onClick={(): void => this.setYear(year)}
                >
                  {year}
                </button>
              );
            })}
          </div>
          <div className={styles.monthGrid}>
            {this.state.showMonths &&
              this.state.allMonths.map((month, i): React.ReactElement => {
                return (
                  <button
                    key={month}
                    className={styles.monthOption}
                    onClick={(): void => this.setMonth(i)}
                  >
                    {month}
                  </button>
                );
              })}
          </div>
        </div>
        {!this.state.showMonths && !this.state.showYears &&
        <div className={styles.calendarGrid}>
          {weekdayShortNames.map((day, i): React.ReactElement => {
            return <span key={i}><strong>{day}</strong></span>;
          })}
          {
            blankDays.map((day, i): React.ReactElement => {
              return <span key={i}></span>;
            })
          }
          {
            daysOfMonth.map((day): React.ReactElement => {
              return (
                <div className={styles.square} key={day}>
                  <div className={styles.content}>
                    <button
                      className={`${this.isSelected(day) ? styles.chosenDay : ''}
                    ${this.isToday(day) ? styles.isToday : ''}`}
                      key={day}
                      onClick={(): void => this.setDay(day)}
                      disabled={this.isDisabled(day)}
                    >
                      {day}
                    </button>
                  </div>
                </div>
              );
            })
          }
        </div>
        }
      </div>
    );
  }
}

export default Calendar;
