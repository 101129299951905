import * as React from 'react';
import {BrowserRouter, StaticRouter, Switch, Route} from 'react-router-dom';
import {asyncComponent} from 'react-async-component';
import Favicon from 'react-favicon';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router';

import Header from 'components/Header';
import Footer from 'components/Footer';
import LoadingPage from 'components/LoadingPage';
import WordpressPageResolver from 'components/WordpressPageResolver';

import '@fortawesome/fontawesome/styles.css';
import fontawesome from '@fortawesome/fontawesome';
import {regionSlugs} from './page/country-landing/CountryLandingPageData';
import ScrollToTop from './ScrollToTop';
import {TranslatorPopup} from './TranslatorPopup';
import {AppCTX} from './AppCTX';
import {StaticProjectsPage83} from './page/projects/StaticProjectsPage83';
import StaticProjectsPage81 from './page/projects/StaticProjectsPage81';
fontawesome.config.autoAddCss = false;

const LoadingPageRender = () => <LoadingPage/>;
LoadingPageRender.displayName = 'LoadingPageRender';

const StaffComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-staff" */
    'components/page/staff/StaffPage'
  ),
  LoadingComponent: LoadingPageRender,
});
const FaqComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-faq" */
    'components/page/faq/FaqPage'
  ),
  LoadingComponent: LoadingPageRender,
});
const ProjectsComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-projects" */
    'components/page/projects/ProjectsPage'
  ),
  LoadingComponent: LoadingPageRender,
});
const ContactComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-contact" */
    'components/page/contact/ContactPage'
  ),
  LoadingComponent: LoadingPageRender,
});
const NewsComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-news" */
    'components/page/news/NewsPage'
  ),
  LoadingComponent: LoadingPageRender,
});
const EventComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-event" */
    'components/page/event/EventPage'
  ),
  LoadingComponent: LoadingPageRender,
});
const NewsletterComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-newsletter" */
    'components/page/newsletter/NewsletterPage'
  ),
  LoadingComponent: LoadingPageRender,
});
const TimelineComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-timeline" */
    'components/page/timeline/TimelinePage'
  ),
  LoadingComponent: LoadingPageRender,
});
const TestimonialsComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-testimonials" */
    'components/page/testimonials/TestimonialsPage'
  ),
  LoadingComponent: LoadingPageRender,
});
const WhatIsEB5Component = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-what-is-eb-5" */
    'components/page/what-is-eb-5/WhatIsEB5Page'
  ),
  LoadingComponent: LoadingPageRender,
});
const IndexComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "page-index" */
    'components/page/index/IndexPage'
  ),
  LoadingComponent: LoadingPageRender,
});
const Error404Component = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "error-404" */
    'components/page/error/404Page'
  ),
  LoadingComponent: LoadingPageRender,
});
const AdminComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "admin" */
    'components/page/admin/AdminPage'
  ),
  LoadingComponent: LoadingPageRender,
});

const CountryLandingPageComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "country-landing" */
    'components/page/country-landing/CountryLandingPage'
  ),
  LoadingComponent: LoadingPageRender,
});

const FormComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "form-page" */
    'components/page/form/FormPage'
  ),
  LoadingComponent: LoadingPageRender,
});

const ConsultationSchedulerPage = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "consulation-calendar" */
    'components/page/schedule-consultation/SchedulerPage'
  ),
  LoadingComponent: LoadingPageRender,
});

const CareersPageComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "careers" */
    'components/page/careers/Careers'
  ),
  LoadingComponent: LoadingPageRender,
});

const Group79ProjectComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "group79" */
    'components/page/projects/Group79Project'
  ),
  LoadingComponent: LoadingPageRender,
});

const Group82ProjectComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "group82" */
    'components/page/projects/Group82Project'
  ),
  LoadingComponent: LoadingPageRender,
});

const StaticProjectsPage80 = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "group80" */
    'components/page/projects/StaticProjectsPage80'
  ),
  LoadingComponent: LoadingPageRender,
});

const StaticProjectsPage90 = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "group90" */
    'components/page/projects/StaticProjectsPage90'
  ),
  LoadingComponent: LoadingPageRender,
});

const StaticProjectsPage91 = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "group91" */
    'components/page/projects/StaticProjectsPage91'
  ),
  LoadingComponent: LoadingPageRender,
});

const StaticProjectsPage88 = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "group88" */
    'components/page/projects/StaticProjectsPage88'
  ),
  LoadingComponent: LoadingPageRender,
});

const StaticProjectsPage84 = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "group84" */
    'components/page/projects/StaticProjectsPage84'
  ),
  LoadingComponent: LoadingPageRender,
});

const StaticProjectsPage86 = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "group86" */
    'components/page/projects/StaticProjectsPage86'
  ),
  LoadingComponent: LoadingPageRender,
});

const StaticProjectsPage89 = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "group89" */
    'components/page/projects/StaticProjectsPage89'
  ),
  LoadingComponent: LoadingPageRender,
});

const FilesharePageComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "fileshare" */
    'components/page/fileshare/FilesharePage'
  ),
  LoadingComponent: LoadingPageRender,
});

const FileshareViewPageComponent = asyncComponent({
  resolve: () => import(
    /* webpackChunkName: "fileshare-view" */
    'components/page/fileshare/FileshareViewPage'
  ),
  LoadingComponent: LoadingPageRender,
});

const Routes = (props) => <div>
  <Header appStore={props.appStore} langStore={props.langStore}>
    <Switch>
      <Route path='/project/500k-eb-5-investment-opportunity' component={() =>
        <Group79ProjectComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/cmb-group-78-hillwood-california-bts' component={() =>
        <StaticProjectsPage80 appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/cmb-group-90-hillwood-treeline' component={() =>
        <StaticProjectsPage90 appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/cmb-group-91-hillwood-dtw-air-cargo' component={() =>
        <StaticProjectsPage91 appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/cmb-group-88-hillwood-detroit-fairgrounds' component={() =>
        <StaticProjectsPage88 appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/cmb-group-86-gable-house-apartments' component={() =>
        <StaticProjectsPage86 appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/cmb-group-84-hillwood-daytona-bts' component={() =>
        <StaticProjectsPage84 appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/cmb-group-83-somi-haywood' component={() =>
        <StaticProjectsPage83 appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/cmb-group-81-inland-empire-industrial' component={() =>
        <StaticProjectsPage81 appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/group-82-hazleton-logistics-park' component={() =>
        <Group82ProjectComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects/group-89-hillwood-city-creek-bts' component={() =>
        <StaticProjectsPage89 appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/admin' component={() =>
        <AdminComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/staff' component={() =>
        <StaffComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/faq' component={() =>
        <FaqComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/projects' component={() =>
        <ProjectsComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/contact' component={() =>
        <ContactComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/news' component={() =>
        <NewsComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/events' component={() =>
        <EventComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/what-is-eb5' component={() =>
        <Redirect to='/what-is-eb-5'/>
      }/>
      <Route path='/what-is-eb-5' component={() =>
        <WhatIsEB5Component
          appStore={props.appStore}
          langStore={props.langStore}
        />
      }/>
      <Route path='/newsletter' component={() =>
        <NewsletterComponent
          appStore={props.appStore}
          langStore={props.langStore}
        />
      }/>
      <Route path='/timeline/:slug' component={({match}) =>
        <TimelineComponent
          slug={match.params.slug}
          appStore={props.appStore}
          langStore={props.langStore}
        />
      }/>
      <Route path='/form' render={() =>
        <FormComponent
          appStore={props.appStore}
          langStore={props.langStore}
        />
      }/>
      <Route path='/testimonials' component={() =>
        <TestimonialsComponent
          appStore={props.appStore}
          langStore={props.langStore}
        />
      }/>
      <Route path='/careers' component={() =>
        <CareersPageComponent
          appStore={props.appStore}
          langStore={props.langStore}
        />
      }/>
      <Route path='/fileshare/view' component={() =>
        <FileshareViewPageComponent
          appStore={props.appStore}
          langStore={props.langStore}
        />
      }/>
      <Route path='/fileshare' component={() =>
        <FilesharePageComponent
          appStore={props.appStore}
          langStore={props.langStore}
        />
      }/>
      {regionSlugs.map((regionSlug) => (
        <Route
          exact
          key={regionSlug}
          path={`/eb-5-visa-for-${regionSlug}`}
          render={() =>
            <CountryLandingPageComponent
              appStore={props.appStore}
              langStore={props.langStore}
              regionSlug={regionSlug}
            />
          }
        />
      ))}
      <Route appStore={props.appStore} langStore={props.langStore}
        path='/calendar' component={() =>
          <ConsultationSchedulerPage appStore={props.appStore} langStore={props.langStore}/>}
      />
      <Route exact path='/' component={() =>
        <IndexComponent appStore={props.appStore} langStore={props.langStore}/>
      }/>
      <Route path='/:slug' component={({match}) =>
        <WordpressPageResolver
          appStore={props.appStore}
          langStore={props.langStore}
          slug={match.params.slug}
        />
      }/>
      <Route component={() => <Error404Component appStore={props.appStore}/>}/>
    </Switch>
    <Footer appStore={props.appStore} langStore={props.langStore}/>
  </Header>
</div>;
Routes.propTypes = {
  appStore: PropTypes.object,
  langStore: PropTypes.object,
};
Routes.displayName = 'Routes';

class App extends React.Component {
  render() {
    return (
      <div ref={(el) => this.props.appStore.appRef = el}>
        <AppCTX.Provider value={{appStore: this.props.appStore, langStore: this.props.langStore}}>
          <Helmet>
            <title>CMB EB-5 Visa</title>
            <meta charSet='utf-8'/>
            <meta httpEquiv='X-UA-Compatible' content='IE=edge'/>
            <meta name='viewport' content='width=device-width, initial-scale=1'/>

            <meta
              name='description'
              content={this.props.langStore.lang.META_DESCRIPTION_GENERIC}
            />

            <link rel='manifest' href='/manifest.json'/>
            <meta name='theme-color' content='#393e44'/>
          </Helmet>
          <TranslatorPopup/>
          <Favicon url={require('images/header/logo.png')}/>
          {typeof window !== 'undefined' &&
            <BrowserRouter>
              <ScrollToTop
                appStore={this.props.appStore}
              >
                <Routes
                  appStore={this.props.appStore}
                  langStore={this.props.langStore}
                />
              </ScrollToTop>
            </BrowserRouter>
          ||
            <StaticRouter
              context={{}}
              location={this.props.appStore.location.href}
            >
              <ScrollToTop
                appStore={this.props.appStore}
              >
                <Routes
                  appStore={this.props.appStore}
                  langStore={this.props.langStore}
                />
              </ScrollToTop>
            </StaticRouter>
          }
        </AppCTX.Provider>
      </div>
    );
  }
}
App.propTypes = {
  appStore: PropTypes.object,
  langStore: PropTypes.object,
};
App.displayName = 'App';

export default App;
