import {observable} from 'mobx';
import {Map} from 'immutable';

export interface INewsArticle {
  slug: string;
  title: string;
  content: string;
  image: string;
  date: string;
}

class PageNewsStore {
  @observable news: false | any[] = false;
  @observable totalPages = 0;
  @observable newsPageNumber = -1;
  @observable unfurls = false;

  @observable newsLists = Map<any, any>();

  @observable newsArticle: INewsArticle | false = false;

  @observable newsSearchString = '';
  @observable newsSearchResultsString = '';
  @observable newsSearchResults: string | false = false;
}


export default PageNewsStore;
