import {observable, action} from 'mobx';

class LangStore {
  @observable lang = null;
  @observable baseLang = null;
  @observable enLang = null;
  @observable langName = '';

  @action public async fetchLang(): void {
    const lang = await (await fetch(`${CONFIG.origin}/api/lang/translator/draft/${this.langName}`)).json();
    if (this.langName == 'en') {
      this.enLang = Object.assign({}, lang);
      this.baseLang = Object.assign({}, lang);
      this.lang = Object.assign({}, lang);
    } else {
      this.enLang = await this.fetchLangByName('en');
      this.baseLang = Object.assign({}, lang);
      this.lang = Object.assign(
        {},
        this.enLang,
        this.baseLang
      );
    }
  }
  @action async fetchLangByName(langName) {
    const lang = await (await fetch(`${CONFIG.origin}/api/lang/translator/draft/${langName}`)).json();
    return lang;
  }
}

export default LangStore;
