import * as React from 'react';
import { IBaseProps } from 'lib/IBasePropTypes';

interface IOEmbedPropTypes extends IBaseProps {
  data: any;
}

class OEmbed extends React.Component {
  props: IOEmbedPropTypes;
  render() {
    return (
      <div
        dangerouslySetInnerHTML={{__html: this.props.data.html}}
        suppressHydrationWarning
      />
    );
  }
}

export default OEmbed;
