export default (str, maxLength, endingContent = '') => {
  let newStr;
  newStr = '';
  let currentIndex = 0;
  let currentWord = '';
  while (currentIndex < str.length + 1) {
    const nextChar = str[currentIndex];
    if (nextChar == ' ' || nextChar == undefined) {
      if (newStr.length + currentWord.length + 1 < maxLength) {
        newStr += ` ${currentWord}`;
      }
      currentIndex ++;
      currentWord = '';
      if (
        newStr.length + currentWord.length >=
        maxLength - endingContent.length
      ) {
        break;
      }
    } else {
      currentWord += str[currentIndex];
      currentIndex++;
    }
  }
  if (newStr.length < str.length) {
    newStr += endingContent;
  }
  return newStr;
};
