import * as React from 'react';
import {action} from 'mobx';
import Helmet from 'react-helmet';
import {observer} from 'mobx-react';

import Breadcrumbs from 'components/Breadcrumbs';
import styles from 'components/stylesheets/styles.scss';
import WordpressPost from 'components/content/WordpressPost';
import MasterHeader from 'components/content/MasterHeader';
import LoadingPage from 'components/LoadingPage';
import stripHtmlTags from 'lib/stripHtmlTags';
import truncatePreserveWord from 'lib/truncatePreserveWord';
import AppStore from 'stores/app';
import LangStore from 'stores/lang';

@observer
class WordpressPage extends React.Component {
  public props: {
    appStore: AppStore;
    slug: string;
    langStore: LangStore;
  }
  componentDidMount() {
    const page = this.props.appStore.pageWordpressPage.page;
    if (page && page.slug !== this.props.slug) {
      this.initData();
    }
  }

  @action
  async initData() {
    this.props.appStore.pageWordpressPage.page = false;
    this.props.appStore.pageWordpressPage.page = await apiFetch(
      this.props.appStore.req,
      `wordpress/page/${this.props.slug}`,
    );
  }

  render() {
    if (!this.props.appStore.pageWordpressPage.page) {
      return <LoadingPage/>;
    }
    const title = this.props.appStore.pageWordpressPage.page.title;
    return (
      <>
        <Helmet>
          <title>
            CMB EB-5 - {title}
          </title>
          <meta
            name='description'
            content={truncatePreserveWord(stripHtmlTags(
              this.props.appStore.pageWordpressPage.page.content
                .toString().replace(/\$/g, 'USD ')
            ), 320).trim()}
          />
          <link
            rel='canonical'
            href={`${this.props.appStore.origin}/${this.props.slug}`}
          />
        </Helmet>
        <MasterHeader>
          {title}
        </MasterHeader>
        <br/>
        <div className={styles.muiContainer}>
          <Breadcrumbs
            appStore={this.props.appStore}
            langStore={this.props.langStore}
            crumbs={[]}
          />
          <br/>
          <br/>
          {(this.props.appStore.pageWordpressPage.page && this.props.appStore.pageWordpressPage.page.content.includes('<h1>')) ?
            '' : <h1 style={{opacity: 0, position: 'absolute', left: '-5000%'}}>
              {this.props.appStore.pageWordpressPage.page.title}
            </h1>
          }
          {this.props.appStore.pageWordpressPage.page &&
            <WordpressPost
              appStore={this.props.appStore}
              langStore={this.props.langStore}
              post={this.props.appStore.pageWordpressPage.page}
              noTitle
            />
          }
        </div>
      </>
    );
  }
}

export default WordpressPage;
