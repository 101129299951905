import autobind from 'autobind';
import {IBaseProps} from 'lib/IBasePropTypes';
import * as React from 'react';
import {observer} from 'mobx-react';

@observer
export default class AdminWrapper extends React.Component {
  public readonly state = {
    isValidatingUser: true,
    validUser: false,
  };
  public readonly props: IBaseProps;

  public async componentDidMount() {
    const validUserResults = await apiFetch(this.props.appStore.req, 'admin/authKey');
    this.setState({
      isValidatingUser: false,
      validUser: validUserResults.success,
    });
  }

  render() {
    const loggedIn = this.props.appStore.loggedIn || this.state.validUser;
    if (!loggedIn) return <div/>;
    return (
      this.props.children
    );
  }
}
