import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as faFacebook from '@fortawesome/fontawesome-free-brands/faFacebook';
import * as faLinkedin from '@fortawesome/fontawesome-free-brands/faLinkedin';
import * as faTwitter from '@fortawesome/fontawesome-free-brands/faTwitter';
import * as faInstagram from '@fortawesome/fontawesome-free-brands/faInstagram';
import * as faYoutube from '@fortawesome/fontawesome-free-brands/faYoutubeSquare';

import styles from 'components/stylesheets/styles.scss';
import AppStore from 'stores/app';

class SocialIcon extends React.Component {
  public props: {
    site: string;
    appStore: AppStore;
    noLabel?: boolean;
  }
  public render(): JSX.Element {
    const sites = {
      linkedin: {
        url: 'https://www.linkedin.com/company/cmbregionalcenters',
        icon: faLinkedin,
        name: 'LinkedIn',
      },
      facebook: {
        url: 'https://www.facebook.com/cmbeb5visa/',
        icon: faFacebook,
        name: 'Facebook',
      },
      twitter: {
        url: 'https://twitter.com/cmbexport',
        icon: faTwitter,
        name: 'Facebook',
        className: styles.faTwitterOverride,
      },
      instagram: {
        url: 'https://www.instagram.com/cmbregionalcenters/',
        icon: faInstagram,
        name: 'Instagram',
      },
      youtube: {
        url: 'https://www.youtube.com/user/CMBExportEB5',
        icon: faYoutube,
        name: 'Youtube',
      },
    };
    const site = sites[this.props.site];
    return (
      <a
        className={[styles.socialIconLink, styles.dp4, ...(site.className && [site.className] || [])].join(' ')}
        rel='noopener'
        target='window'
        href={site.url}
        aria-label={`Visit CMB on ${site.name}`}
      >
        <FontAwesomeIcon
          icon={site.icon}
          size='6x'
        />
        {!this.props.noLabel &&
          <span className={styles.socialIconText}>
            {sites[this.props.site].name}
          </span>
        }
      </a>
    );
  }
}

export default SocialIcon;
