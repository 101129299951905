import * as React from 'react';

import styles from 'components/stylesheets/styles.scss';

class LoadingPage extends React.Component {
  render() {
    return (
      <div className={styles.loadingPage}>
        <div className={styles.loadingPulse}/>
      </div>
    );
  }
}

export default LoadingPage;
