import {observable} from 'mobx';
import {Map} from 'immutable';

class PageEventsStore {
  @observable public events: {} = {};
  @observable public eventsList: any = false;
}


export default PageEventsStore;
