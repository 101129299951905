import * as React from 'react';

import {ICountryLandingPage} from 'lib/ICountryLandingPage';

export const regionSlugs = [ //Keep this updated with regions, allows tree shaking out data from regions.
  'brazil',
  'nigeria',
]

export const regions: ICountryLandingPage[] = [{
  name: 'Brazil',
  slug: 'brazil',
  testimonialsCountryNames: ['Brazil'],
  staffRegionNames: ['Latin America'],
  languages: [{
    ourTeamHeading: 'Our EB-5 Brazil Team',
    ourTeamContent: <>
      <p>
        CMB specializes in bringing the best EB-5 case managers to Brazilian clients 
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas convallis, sem et tempus finibus, 
        purus massa tempus turpis, at viverra odio velit eu felis. Aenean vitae nisl diam. Interdum et 
        malesuada fames ac ante ipsum primis in faucibus. Suspendisse vestibulum tincidunt ipsum a auctor.
        Donec ut sapien a diam convallis maximus suscipit sit amet erat. Duis eget lacinia purus. 
        Nulla aliquet, dolor et faucibus tempor, nunc urna blandit ante, quis vestibulum nunc tellus 
        egestas mauris. Maecenas id venenatis ligula. Donec imperdiet tellus eu orci efficitur ultrices.
        Nunc sit amet sapien urna. Cras at enim nec lacus commodo lobortis. Nunc consectetur metus ac 
        efficitur mollis. Aliquam non condimentum diam, a tempor erat. Curabitur tincidunt ante nisl,
        at consectetur elit scelerisque sed.
      </p>
    </>,
    permanentResidencyHeading: 'Permanent US Residency for Brazilian Investors',
    permanentResidencyContent: <>
      Etiam luctus tempor urna, et imperdiet metus cursus in. In hendrerit magna nunc, vitae accumsan turpis sodales vitae. Proin leo nisl, elementum non metus placerat, mattis rhoncus metus. In in magna vitae risus sollicitudin maximus. Vivamus convallis nunc vitae massa suscipit sollicitudin. Aenean porttitor massa sit amet lacus dictum, eget vulputate ante condimentum. Nullam id commodo ex. Curabitur cursus quis ligula ut lacinia. Aliquam nec vehicula risus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.',
    </>,
    testimonialsHeading: 'Testimonials from our Brazilian clients',
    languageCode: 'en',
  }]
}, {
  name: 'Nigeria',
  slug: 'nigeria',
  testimonialsCountryNames: ['Nigeria'],
  staffRegionNames: ['France/Africa/Middle East'],
  languages: [{
    ourTeamHeading: 'Our EB-5 Nigeria Team',
    ourTeamContent: <>
      <p>
        CMB specializes in bringing the best EB-5 case managers to Nigerian clients 
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas convallis, sem et tempus finibus, 
        purus massa tempus turpis, at viverra odio velit eu felis. Aenean vitae nisl diam. Interdum et 
        malesuada fames ac ante ipsum primis in faucibus. Suspendisse vestibulum tincidunt ipsum a auctor.
        Donec ut sapien a diam convallis maximus suscipit sit amet erat. Duis eget lacinia purus. 
        Nulla aliquet, dolor et faucibus tempor, nunc urna blandit ante, quis vestibulum nunc tellus 
        egestas mauris. Maecenas id venenatis ligula. Donec imperdiet tellus eu orci efficitur ultrices.
        Nunc sit amet sapien urna. Cras at enim nec lacus commodo lobortis. Nunc consectetur metus ac 
        efficitur mollis. Aliquam non condimentum diam, a tempor erat. Curabitur tincidunt ante nisl,
        at consectetur elit scelerisque sed.
      </p>
    </>,
    permanentResidencyHeading: 'Permanent US Residency for Nigerian Investors',
    permanentResidencyContent: <>
      Etiam luctus tempor urna, et imperdiet metus cursus in. In hendrerit magna nunc, vitae accumsan turpis sodales vitae. Proin leo nisl, elementum non metus placerat, mattis rhoncus metus. In in magna vitae risus sollicitudin maximus. Vivamus convallis nunc vitae massa suscipit sollicitudin. Aenean porttitor massa sit amet lacus dictum, eget vulputate ante condimentum. Nullam id commodo ex. Curabitur cursus quis ligula ut lacinia. Aliquam nec vehicula risus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.',
    </>,
    testimonialsHeading: 'Testimonials from our Nigerian clients',
    languageCode: 'en',
  }]
}];