import * as React from 'react';
import {observer} from 'mobx-react';

import styles from 'components/stylesheets/styles.scss';
import Link from 'components/Link';
import AppStore from 'stores/app';
import LangStore from 'stores/lang';
import {Translatable} from 'components/Translatable';

@observer
class Reg506c extends React.Component {
  public readonly props: {
    appStore: AppStore;
    langStore: LangStore;
    children: React.ReactNode;
  }
  constructor(props) {
    super(props);

    this.allow = this.allow.bind(this);
    this.deny = this.deny.bind(this);
  }
  allow(e) {
    e.preventDefault();
    this.props.appStore.cookies =
      this.props.appStore.cookies.set('506C_VALID_VIEWER', 1);
  }
  deny(e) {
    e.preventDefault();
    this.props.appStore.cookies =
      this.props.appStore.cookies.set('506C_VALID_VIEWER', 0);
  }
  public render(): JSX.Element {
    const project506cViewer =
      this.props.appStore.cookies.get('506C_VALID_VIEWER');
    const popup = project506cViewer === undefined;
    const denied = project506cViewer == 0;
    const returnInput = (
      <input
        type='hidden'
        name='returnurl'
        value={this.props.appStore.location.href}
      />
    );
    const {lang} = this.props.langStore;
    const countryCode = this.props.appStore.isoCode === 'US' ? 'US' : 'NON-US';
    return (
      <div>
        {popup &&
          <div
            className={[
              styles.muiContainer,
              styles.project506cContainer,
            ].join(' ')}
          >
            <div
              className={styles.project506cBorder}
              onClick={()=>{}}
              role='presentation'
            >{/* Empty onclick for legacy ios support*/}
              <h2 style={{fontSize: '24px', fontWeight: 400, color: 'rgb(26, 56, 123)'}}>
                <Translatable k={`PROJECTS_506C_${countryCode}_QUESTION`}/>
              </h2>
              <hr/>
              <h3 style={{textAlign: 'center', fontSize: '2rem', fontWeight: 400}}>
                <Translatable k={`PROJECTS_506C_${countryCode}_SUBTITLE`}/>
                <div
                  className={styles.helpTip}
                  onClick={()=>{}}
                  role='presentation'
                >
                  <div>
                    <p>
                      <Translatable k={`PROJECTS_506C_${countryCode}_TIP_PARAGRAPH`}/>
                    </p>
                    <ol className={styles.lowerAlpha}>
                      <li>
                        <Translatable k={`PROJECTS_506C_${countryCode}_TIP_OPTION_1`}/>
                      </li>
                      <li>
                        <Translatable k={`PROJECTS_506C_${countryCode}_TIP_OPTION_2`}/>
                      </li>
                    </ol>
                  </div>
                </div>
              </h3>
              <div className={styles.project506cButtons}>
                <form
                  method='post'
                  action='/api/projects/506c'
                >
                  <input
                    type='hidden'
                    name={`${countryCode}option`}
                    value={`${countryCode}option1`}
                  />
                  {returnInput}
                  <button
                    className={[
                      styles.muiBtn,
                      styles.muiBtnPrimary,
                      styles.muiBtnLarge,
                      styles.btn506c,
                    ].join(' ')}
                    onClick={(e) => this.allow(e)}
                  >
                    {countryCode === 'US' ?
                      <Translatable k={`CONFIRM_YES`}/> :
                      <Translatable k={`DENY_NO`}/>
                    }
                  </button>
                </form>
                <form
                  method='post'
                  action='/api/projects/506c'
                >
                  <input
                    type='hidden'
                    name={`${countryCode}option`}
                    value={`${countryCode}option2`}
                  />
                  {returnInput}
                  <button
                    className={[
                      styles.muiBtn,
                      styles.muiBtnFlat,
                      styles.muiBtnLarge,
                      styles.btn506c,
                    ].join(' ')}
                    onClick={(e) => this.deny(e)}
                  >
                    {countryCode === 'US' ?
                      <Translatable k={`DENY_NO`}/> :
                      <Translatable k={`CONFIRM_YES`}/>
                    }
                  </button>
                </form>
              </div>
              <div className={styles.project506cNote}>
                <Translatable k={`PROJECTS_506C_${countryCode}_EXTRA_INFO`}/>
              </div>
            </div>
          </div>
        }
        {!denied &&
          <div
            className={[
              ...[popup && styles.project506cHidden || undefined],
              styles.project506cOverlapContainer,
            ].join(' ')}
          >
            {popup && <div className={styles.project506cOverlap}/>}
            {this.props.children}
          </div>
        ||
          <div>
            <p className={styles.paragraph}>
              <Translatable k={`PROJECTS_506C_CANNOT_SHOW_P1_1`}/>
              {' '}
              <Link
                appStore={this.props.appStore}
                scrollToTop
                to='/projects/past'
              >
                <Translatable k={`PROJECTS_506C_CANNOT_SHOW_P1_2`}/>
              </Link>
              {' '}
              <Translatable k={`PROJECTS_506C_CANNOT_SHOW_P1_3`}/>
              {' '}
              <Link appStore={this.props.appStore} scrollToTop to='/contact'>
                <Translatable k={`PROJECTS_506C_CANNOT_SHOW_P1_4`}/>
              </Link>
              {' '}
              <Translatable k={`PROJECTS_506C_CANNOT_SHOW_P1_5`}/>
            </p>
          </div>
        }
      </div>
    );
  }
}

export default Reg506c;
