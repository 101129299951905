import React from 'react';
import PropTypes from 'prop-types';

import styles from 'components/stylesheets/styles.scss';

const MasterHeader = (props) => (
  <div className={styles.muiAppbar}>
    <table width='100%'>
      <tbody>
        <tr>
          <td className={styles.muiAppbarHeight} align='center'>
            {props.h1 &&
              <h1>{props.children}</h1>
            ||
              <h2>
                {props.children}
              </h2>
            }
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
MasterHeader.propTypes = {
  children: PropTypes.node,
  h1: PropTypes.bool,
};

export default MasterHeader;
