import {observable} from 'mobx';

class PageFAQStore {
  @observable faqs: any[] | false = false;
  @observable faqRelatedQuestions = {};
  @observable fetchDatas = {};
}


export default PageFAQStore;
