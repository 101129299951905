import * as React from 'react';
import {Link as VanillaLink} from 'react-router-dom';
import {IDocument} from 'lib/IDocument';

interface ILinkProps {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  scrollToTop?: boolean;
  to: string;
  className?: string;
  appStore: any;
  children?: React.ReactNode;
  [key: string]: any;
}

class Link extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }
  props: ILinkProps;
  onClick(e) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
    if (this.props.scrollToTop) {
      const doc = document as IDocument;
      const windowOffset =
        (window.pageYOffset || doc.scrollTop || 0) - (doc.clientTop || 0);
      if (windowOffset > 0) {
        window.scrollTo(0, Math.round(0));
      } else {
        setTimeout(() => {
          if (windowOffset + window.innerHeight < 0) {
            window.scrollTo(0, Math.round(0));
          }
        }, 3);
      }
    }
  }
  render() {
    const newProps: ILinkProps = Object.assign({}, this.props);
    delete newProps.onClick;
    delete newProps.scrollToTop;
    delete newProps.appStore;
    return (
      <VanillaLink onClick={this.onClick} {...newProps}/>
    );
  }
}

export default Link;
