import * as React from 'react';
import * as moment from 'moment-timezone';
import autobind from 'autobind';
import {observer} from 'mobx-react';

import LangStore from 'stores/lang';
import SchedulerStore from 'stores/scheduler-store';
import styles from 'components/stylesheets/styles.scss';

export interface TimeslotsPropsInterface {
  langStore: LangStore;
}
@observer
class TimeSlots extends React.Component<TimeslotsPropsInterface> {
  public constructor(props) {
    super(props);
  }

  @autobind
  private setTime(e: string): void {
    SchedulerStore.selectedTime = e;
  }

  @autobind
  private confirmTime(): void {
    SchedulerStore.showContactForm = true;
  }

  @autobind
  private isDisabled(time: string): boolean {
    const timeCST = moment(time).tz('America/Chicago');
    const nowCST = moment().tz('America/Chicago');
    const selectedCST = moment(SchedulerStore.selectedDay).tz('America/Chicago');
    // If it is earlier than 11pm today no need to disable times for next day
    if (nowCST.get('hour') < 23) {
      return false;
    } else {
      // Always disable early hours for today
      if (nowCST.clone().isSame(selectedCST, 'day')) {
        return (timeCST.get('hour') < 9 && !(timeCST.get('hour') == 8 && timeCST.get('minute') == 30));
      }
      // Is chosen day tomorrow or is today Friday and the chosen day is Monday?
      if (nowCST.clone().add(1, 'day').isSame(selectedCST, 'day') ||
        (nowCST.format('e') == '5' && nowCST.clone().day(8).isSame(selectedCST, 'day'))) {
        // It's past 11pm today and next business day is selected. Disable early hours
        return (timeCST.get('hour') < 9 && !(timeCST.get('hour') == 8 && timeCST.get('minute') == 30));
      }
    }
    return false;
  }

  public render(): React.ReactNode {
    return (
      <div className={styles.timeContainer}>
        <span className={styles.timeHeading}>{moment(SchedulerStore.selectedDay).format('MMMM Do')}</span>
        <div className={styles.timeSlotContainer}>
          {SchedulerStore.todaysHours && SchedulerStore.todaysHours.map((time, i): React.ReactNode => {
            return this.isDisabled(time) ? null :
              <div key={i} className={styles.timeButtonContainer}>
                <button
                  className={`${styles.timeSlot} ${SchedulerStore.selectedTime === time ? styles.selectedTime : ''}`}
                  onClick={(): void => this.setTime(time)}
                >
                  {moment(time).format('h:mm A')}
                </button>
                {SchedulerStore.selectedTime === time &&
                <button
                  onClick={(): void => this.confirmTime()}
                  className={styles.confirmTimeButton}
                  title='Schedule this time'
                >
                  {this.props.langStore.lang['CONFIRM'] ||
              'Confirm'}
                </button>
                }
              </div>;
          })}
          {SchedulerStore.todaysHours.length < 1 &&
            <span>{this.props.langStore.lang['NO_AVAILABLE_TIMES'] ||
              'There are no available times today'}
            </span>
          }
        </div>
      </div>
    );
  }
}

export default TimeSlots;
