import * as React from 'react';
import styles from 'components/stylesheets/styles.scss';
import {observer} from 'mobx-react';
import {AppCTX} from 'components/AppCTX';

@observer
export class Translatable extends React.Component {
  public props: any;

  public static contextType = AppCTX;
  public context!: React.ContextType<typeof AppCTX>;

  public render(): JSX.Element {
    if (!this.context || !this.context.appStore) return <></>;
    this.context.langStore.lang; // Listen
    const picking = this.context.appStore.currentTranslatorOpen;
    const pickedThis = this.context.appStore.currentTranslatorKey == this.props.k;
    if (!picking) {
      return this.context.langStore.lang[this.props.k] || '[Unknown]';
    }
    return (
      <span style={pickedThis ? {
        cursor: 'pointer', background: 'rgba(0, 255, 0, 0.4)', zIndex: '100000',
      } : {
        cursor: 'pointer', background: 'rgba(255, 255, 0, 0.4)', zIndex: '100000',
      }} onClick={(e): void => {
        e.stopPropagation();
        e.preventDefault();
        this.context.appStore.currentTranslatorKey = this.props.k;
        this.context.appStore.currentTranslatorValue = this.context.langStore.lang[this.props.k] || '';
        this.context.appStore.currentTranslatorReset = this.context.langStore.lang[this.props.k] || '';
      }} role='button' tabIndex={0}>
        {this.context && this.context.langStore.lang[this.props.k] || '[Unknown]'}
      </span>
    );
  }
}
