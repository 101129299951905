import * as React from 'react';
import {observer} from 'mobx-react';
import {AppCTX} from 'components/AppCTX';

import styles from 'components/stylesheets/styles.scss';
import Image from 'components/Image';
import AdminWrapper from 'components/page/admin/AdminWrapper';

const flags = [
  'en',
  'es',
  // 'fr',
  'ko',
  'pt',
  'ru',
  'vi',
  'zh',
];

@observer
export class TranslatorPopup extends React.Component {
  public static contextType = AppCTX;
  public context!: React.ContextType<typeof AppCTX>;
  public render(): JSX.Element {
    if (!this.context || !this.context.appStore || !this.context.langStore.enLang) {
      return <div className={styles.translatorContainer}/>;
    }
    this.context.appStore.currentTranslatorValue;
    const style: any = {
      bottom: undefined,
    };
    if (this.context.appStore.currentTranslatorOpen) {
      style.bottom = 0;
    }
    if (this.context.appStore.currentTranslatorLeft) {
      style.left = 0;
      style.right = 'unset';
    }
    return (
      <>
        <AdminWrapper appStore={this.context.appStore}>
          <div className={styles.translatorContainer} style={style}>
            <button onClick={() => {
              this.context.appStore.currentTranslatorKey = '';
              this.context.appStore.currentTranslatorValue = this.context.langStore.lang[this.props.k] || '';
              this.context.appStore.currentTranslatorReset = this.context.langStore.lang[this.props.k] || '';
              this.context.appStore.currentTranslatorOpen = !this.context.appStore.currentTranslatorOpen;
            }}>Toggle Translator</button>
            <div className={styles.translatorWindow}>
              <div>
                <h2>Translating</h2>
                <button onClick={() => {
                  this.context.appStore.currentTranslatorLeft = !this.context.appStore.currentTranslatorLeft;
                }}>Switch Side</button>
              </div>
              <div>
                {flags.map((flag): React.ReactNode => (
                  <img
                    containerClassName={styles.headerFlagContainer}
                    key={flag}
                    alt={flag}
                    src={require(`images/header/flag_${flag}.svg`)}
                    appStore={this.context.appStore}
                    linkTo={`/api/lang/override/${flag}/${
                      encodeURIComponent(
                        window.location.pathname
                      )
                    }`}
                    style={{height: '22px'}}
                    onClick={(e: React.MouseEvent<HTMLImageElement>): void => {
                      e.preventDefault();
                      this.context.appStore.cookies =
                      this.context.appStore.cookies.set('overrideLanguage', flag);
                      window.location.reload();
                    }}
                  />
                ))}
              </div>
              <div>
                <textarea disabled value={this.context.langStore.enLang[this.context.appStore.currentTranslatorKey]}/>
                <textarea
                  value={this.context.appStore.currentTranslatorValue}
                  onChange={(e): void => {
                    this.context.appStore.currentTranslatorValue = e.target.value;
                  }}
                />
              </div>
              <div>
                <button
                  onClick={(): void =>
                    this.context.appStore.currentTranslatorValue = this.context.appStore.currentTranslatorReset
                  }
                >
                  Reset
                </button>
                <button
                  onClick={async (): Promise<void> => {
                    this.context.langStore.baseLang = Object.assign({}, this.context.langStore.baseLang, {
                      [this.context.appStore.currentTranslatorKey]: this.context.appStore.currentTranslatorValue,
                    });
                    this.context.langStore.lang = Object.assign({}, this.context.langStore.lang, {
                      [this.context.appStore.currentTranslatorKey]: this.context.appStore.currentTranslatorValue,
                    });
                    const data = await apiFetch(this.context.appStore.req, `lang/translator/save/${this.context.langStore.langName}`, {
                      method: 'POST',
                      body: JSON.stringify(Object.assign(
                        {},
                        {langData: this.context.langStore.baseLang},
                      )),
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      json: true,
                    });
                    console.log(data);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </AdminWrapper>
      </>
    );
  }
}
