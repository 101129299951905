import {observable} from 'mobx';
import {IPastProject} from 'components/page/admin/projects/AdminPastProjects';

class PageIndexStore {
  @observable projects: false | IPastProject[] = false;

  @observable frontpageProject = false;

  @observable stats = {};
  @observable news = false;
  @observable events: any = false;
}


export default PageIndexStore;
