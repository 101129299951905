import * as React from 'react';

import Link from 'components/Link';
import styles from 'components/stylesheets/styles.scss';
import AppStore from 'stores/app';

class Error404Page extends React.Component {
  public props: {
    appStore: AppStore;
    children?: React.ReactNode;
  }
  public componentDidMount(): void {
    this.props.appStore.is404 = true;
  }
  private initData(): void {
    this.props.appStore.is404 = true;
  }
  public componentWillUnmount(): void {
    this.props.appStore.is404 = false;
  }
  public render(): JSX.Element {
    return (
      <div className={styles.muiContainer}>
        <h1>Sorry</h1>
        <h4>We were not able to locate the page you are looking for.</h4>
        <ul className={styles.page404list}>
          <li>The page may have been moved, updated, or deleted.</li>
          <li>The page that sent you here may be out of date</li>
          <li>There might be an issue with the web service</li>
          <li>There may be a mistake in the web address.</li>
        </ul>
        <h4>Please try the the following to locate the page</h4>
        <ul className={styles.page404list}>
          <li>Double check the spelling of the address you entered</li>
          <li>Use the navigation bar at the top of the page</li>
          <li>
            <Link
              appStore={this.props.appStore}
              scrollToTop
              to='/contact'
            >
              Contact Us
            </Link>
            {' for assistance locating the page you are looking for'}
          </li>
        </ul>
      </div>
    );
  }
}

export default Error404Page;
