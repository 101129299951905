import {observable} from 'mobx';

export interface IWordpressPage {
  slug: string;
  title: string;
  content: string;
}

class PageWordpressPage {
  @observable page: IWordpressPage | false = false;
}


export default PageWordpressPage;
