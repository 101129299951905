import * as React from 'react';

import Link from 'components/Link';
import styles from 'components/stylesheets/styles.scss';
import AppStore from 'stores/app';
import LangStore from 'stores/lang';
import SocialIcon from 'components/content/SocialIcon';
import {Translatable} from 'components/Translatable';

interface IFooterLinkProps {
  page: string;
  pageTitle: string;
  appStore: AppStore;
}

class Footer extends React.Component {
  public props: {
    appStore: AppStore;
    langStore: LangStore;
    children?: React.ReactNode;
  }
  public render(): JSX.Element {
    const {lang} = this.props.langStore;
    return (
      <div>
        <footer className={styles.footer}>
          <div className={styles.muiContainer}>
            <div className={styles.footerLogo}>
              <img src={require('images/logo.svg')} alt='CMB Regional Centers Logo. Established 1997'/>
            </div>
            <div className={styles.footerInfoColumn}>
              <div>
                <div><strong><Translatable k='FOOTER_HEADQUARTERS_HEADER'/></strong></div>
                <div>5910 N Central Expy, Suite 1000</div>
                <div>Dallas, Texas 75206</div>
              </div>
              <div>
                <div><strong><Translatable k='FOOTER_REGIONAL_OFFICE_HEADER'/></strong></div>
                <div>7819 42 Street West</div>
                <div>Rock Island, Illinois USA 61201</div>
              </div>
            </div>
            <div className={styles.footerInfoColumn} style={{flexBasis: '210px'}}>
              <div>
                <div><strong><Translatable k='FOOTER_CONTACT_US'/></strong></div>
                <div>+1 309-797-1550</div>
                <div>info@cmbeb5visa.com</div>
              </div>
            </div>
            <div className={styles.footerInfoLastColumnSpacer}/>
            <div className={styles.footerInfoColumn}>
              <a
                className={
                  [
                    styles.muiBtn, styles.muiBtnAccent, styles.btnFooterLogin,
                  ].join(' ')
                }
                href='https://cmbeb5visa.sharefile.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Translatable k='FOOTER_LOGIN'/>
              </a>
              <Link
                appStore={this.props.appStore}
                className={
                  [
                    styles.muiBtn, styles.muiBtnAccent, styles.btnFooterContact,
                  ].join(' ')
                }
                scrollToTop
                to='/contact'
              >
                <Translatable k='FOOTER_CONTACT_US'/>
              </Link>
              <div className={styles.footerMinorLinksContainer}>
                <Link to='/careers' className={styles.footerMinorLink} appStore={this.props.appStore} scrollToTop>
                  <Translatable k='FOOTER_CAREERS'/>
                </Link>
                <Link
                  to='/privacy-policy' className={styles.footerMinorLink}
                  appStore={this.props.appStore} scrollToTop
                >
                  <Translatable k='FOOTER_PRIVACY_POLICY'/>
                </Link>
                <a
                  href='https://www.aila.org/'
                  rel='noopener noreferrer' target='_blank'
                  className={styles.footerMinorLink}
                >
                  <Translatable k='FOOTER_AMERICAN_IMMIGRATION_LAWYERA_ASSOCIATION'/>
                </a>
              </div>
            </div>
            <div className={styles.footerDisclaimer}>
              <Translatable k='FOOTER_ADA_1'/>{' '}
              <Link to='/accessibility-statement' appStore={this.props.appStore} scrollToTop>
                <Translatable k='FOOTER_ADA_2_A'/>
              </Link><br/><br/>
              <a
                href='https://transparency-in-coverage.uhc.com/'
                rel='noopener noreferrer' target='_blank'
                className={styles.footerMinorLink}
              >
                Healthcare Transparency in Coverage
              </a><br/><br/>
              <Translatable k='FOOTER_SECURITIES'/>
            </div>
          </div>
        </footer>
        <footer className={styles.subFooter}>
          <div className={styles.muiContainer}>
            <div>
              © CMB Regional Centers. All rights reserved.
            </div>
            <div className={styles.footerSocials}>
              <span><Translatable k='FOOTER_FOLLOW_US'/>:</span>
              <SocialIcon
                appStore={this.props.appStore}
                site='facebook'
                noLabel
              />
              <SocialIcon
                appStore={this.props.appStore}
                site='linkedin'
                noLabel
              />
              <SocialIcon
                appStore={this.props.appStore}
                site='instagram'
                noLabel
              />
              <SocialIcon
                appStore={this.props.appStore}
                site='twitter'
                noLabel
              />
              <SocialIcon
                appStore={this.props.appStore}
                site='youtube'
                noLabel
              />
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;
