import * as React from 'react';
import styles from 'components/stylesheets/styles.scss';
import autobind from 'autobind';
import * as moment from 'moment-timezone';
import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/free-solid-svg-icons';

import AppStore from 'stores/app';
import LangStore from 'stores/lang';
import Calendar from './Calendar';
import TimeSlots from './TimeSlots';
import SchedulerForm from './ConsultationSchedulerForm';
import SchedulerStore from 'stores/scheduler-store';

export interface SchedulerPropsInterface {
  appStore: AppStore;
  langStore: LangStore;
}

@observer
class ConsulationScheduler extends React.Component<SchedulerPropsInterface> {
  public props: SchedulerPropsInterface;
  public constructor(props) {
    super(props);
  }

  @autobind
  private reset(): void {
    SchedulerStore.selectedDay = moment();
    SchedulerStore.selectedTime = undefined;
    SchedulerStore.showContactForm = false;
  }

  public render(): React.ReactNode {
    const chosenDT = moment(SchedulerStore.selectedTime);
    return (
      <div className={styles.schedulerContainer}>
        <div className={styles.leftCol}>
          <div className={styles.infoTitle}>
            <h2>{this.props.langStore.lang['CONSULTATION_INFO_TITLE'] ||
              'CMB Regional Centers'}
            </h2>
            <h4>
              {this.props.langStore.lang['CONSULTATION_INFO_SUBTITLE'] ||
              'FREE EB-5 Phone Consultation'}
            </h4>
            <h4>
              <FontAwesomeIcon
                icon={faClock}
              /> {'  '}
              {this.props.langStore.lang['CONSULTATION_INFO_TIME'] ||
                  '30 minutes'}
            </h4>
          </div>
          <p>{this.props.langStore.lang['CONSULTATION_INFO_SUMMARY'] ||
              'The free consultation will involve an explanation of the EB-5 program, current regulations, investment costs and the immigration process.' // eslint-disable-line
          }
          </p>
          <p>{this.props.langStore.lang['CONSULTATION_INFO_SUMMARY2'] ||
              'Currently the minimum investment amount for an EB-5 visa is $800,000 plus associated costs.'}
          </p>
          {SchedulerStore.showContactForm &&
            <div>
              <h4>{chosenDT.format('MMMM Do YYYY')}</h4>
              <h4>{chosenDT.format('h:mm A')} {moment.tz.guess()}</h4>
              <br/>
              <p>{this.props.langStore.lang['CONSULTATION_INFO_CHANGEDAY'] ||
              'Need a different day or time?'}</p>
              <button className={[styles.muiBtn, styles.changeBtn].join(' ')} onClick={this.reset} title='Change time'>
                {this.props.langStore.lang['BANK_INFORMATION_BACK_TO_SELECTION'] ||
                'Back to Selection'}
              </button>
            </div>
          }
        </div>
        <div className={styles.rightCol}>
          {SchedulerStore.submitSuccess &&
            <div className={styles.successMessage}>
              <h2>{this.props.langStore.lang['CONSULTATION_SUCCESS'] ||
              'Your free consultation has been scheduled!'}
              </h2>
              <p>{this.props.langStore.lang['CONSULTATION_SUCCESS_CONFIRMATION'] ||
                `An Investment Relations Manager will contact you at the time you requested.
                You should also receive an email shortly confirming your consultation.`}
              </p>
            </div>
          }
          {SchedulerStore.showContactForm && !SchedulerStore.submitSuccess &&
              <div>
                <SchedulerForm
                  appStore={this.props.appStore}
                  langStore={this.props.langStore}
                  localTime={[chosenDT.format('MMMM Do YYYY h:mm A'), moment.tz.guess()].join(' ')}
                  cstTime={moment(chosenDT).tz('America/Chicago').format('MMMM D YYYY h:mm A')}
                />
              </div>
          }
          {!SchedulerStore.submitSuccess && !SchedulerStore.showContactForm &&
              <div className={styles.rightColContainer}>
                <Calendar />
                <TimeSlots langStore={this.props.langStore}/>
              </div>
          }
        </div>
      </div>
    );
  }
}

export default ConsulationScheduler;
