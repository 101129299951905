import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {observer} from 'mobx-react';
import _chunk from 'lodash/chunk';

import Link from 'components/Link';
import RisingNumeral from 'components/content/RisingNumeral';
// import EB5IsChanging from 'components/content/EB5IsChanging';
// import EB5IsChangingPopup from 'components/content/EB5IsChangingPopup';
import styles from 'components/stylesheets/styles.scss';
import Image from 'components/Image';
import AppStore from 'stores/app';
import LangStore from 'stores/lang';
import {RouteComponentProps} from 'react-router';
import SchedulerModal from 'components/content/ConsultationSchedulerModal';
import {action} from 'mobx';
import {DataPrivacyToast} from 'components/DataPrivacyToast';
import {Translatable} from 'components/Translatable';

interface IHeaderLinkProps {
  page: string;
  pageTitle: string;
  appStore: AppStore;
  onClick: () => void;
}

const HeaderLink = ({page, pageTitle, appStore, onClick}: IHeaderLinkProps): JSX.Element => {
  return (
    <Link scrollToTop to={`/${page}`} appStore={appStore} onClick={onClick}>
      <div className={styles.headerButton}>
        <Translatable k={pageTitle}/>
      </div>
    </Link>
  );
};

// const HeaderRedBar = () => {
//   return (
//     <div className={styles.headerRedBar}>
//       We are currently in the process of updating our website to comply with the new EB-5 Regulations
//     </div>
//   );
// };

interface IHeaderProps extends RouteComponentProps<{}> {
  appStore: AppStore;
  langStore: LangStore;
}

@observer
class Header extends React.Component<IHeaderProps> {
  private headerElementRef: React.RefObject<HTMLDivElement>;
  public props: IHeaderProps;
  public state: {projectCount: number; hamburgerMenuChecked: boolean; languageSelectOpen: boolean};

  public constructor(props) {
    super(props);
    this.headerElementRef = React.createRef();
    this.state = {
      projectCount: 0,
      hamburgerMenuChecked: false,
      languageSelectOpen: false,
    };
  }
  private overrideLanguage(flag): void {
    this.props.appStore.cookies =
      this.props.appStore.cookies.set('overrideLanguage', flag);
    window.location.reload();
  }
  private updateHeaderBottom(): void {
    if (this.headerElementRef.current) {
      this.props.appStore.headerBottom =
        this.headerElementRef.current.getBoundingClientRect().bottom +
        document.documentElement.scrollTop;
    }
  }
  public async componentDidMount(): Promise<void> {
    this.initData();
    this.updateHeaderBottom();
  }
  public componentDidUpdate(): void {
    this.updateHeaderBottom();
  }

  @action
  private async initData(): Promise<void> {
    this.props.appStore.pageProjects.projects = await apiFetch(
      this.props.appStore.req,
      `projects/all`
    );
    const stat = await apiFetch(
      this.props.appStore.req,
      `projects/statistic/sum/name`
    );
    this.setState({
      projectCount: stat.value,
    });
  }
  public render(): JSX.Element {
    const {lang} = this.props.langStore;
    const {pathname} = this.props.location;
    const isHomepage = pathname == '/';
    const headerClassName = [
      styles.headerBackground,
      ...[isHomepage ? styles.homepage : undefined],
    ].join(' ');
    const flags = [
      'en',
      'es',
      'ko',
      'pt',
      'ru',
      'vi',
      'zh',
    ];
    const flaglations = [
      'English',
      'Español',
      '한국어',
      'Português',
      'русский',
      'Tiếng Việt',
      '中国人',
    ];
    return (
      <header className={headerClassName}>
        <DataPrivacyToast/>
        {this.props.appStore.showSchedulerModal &&
          <SchedulerModal appStore={this.props.appStore} langStore={this.props.langStore}/>
        }
        <div className={styles.headerBackgroundTopContent}>
          <div className={styles.headerBackgroundTopContentContainer}>
            <button
              className={styles.headerManageLanguageButton}
              onClick={(e) => {
                e.preventDefault(); this.setState({languageSelectOpen: !this.state.languageSelectOpen});
              }}
            >
              <div>
                {this.props.langStore.langName &&
                  <img
                    className={styles.headerFlagContainer}
                    alt={this.props.langStore.langName ? this.props.langStore.langName : 'en'}
                    src={require(`images/header/flag_${this.props.langStore.langName ? this.props.langStore.langName : 'en'}.svg`)}
                  />
                }
              </div>{' '}
              / {flaglations[flags.indexOf(this.props.langStore.langName)] || ''}
              <div style={{flex: '1'}}/>
              <div className={styles.languageSelectCaret}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  aria-hidden='true' role='img'
                  width='1em' height='1em' preserveAspectRatio='xMidYMid meet'
                  viewBox='0 0 512 512'
                >
                  <path fill='currentColor' d='m98 190.06l139.78 163.12a24 24 0 0 0 36.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62Z'></path>
                </svg>
              </div>
              {this.state.languageSelectOpen &&
                <>
                  <div
                    className={styles.headerManageLanguageBackground}
                    onClick={(e) => {
                      e.preventDefault(); this.setState({languageSelectOpen: false});
                    }}
                    role='button'
                  />
                  <div className={styles.headerManageLanguagePopup}>
                    {flags.map((flag, i): JSX.Element => (
                      <Link
                        to={`/api/lang/override/${flag}/${
                          encodeURIComponent(
                            this.props.location.pathname
                          )
                        }`}
                        key={flag}
                        appStore={this.props.appStore}
                        onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => {
                          e.preventDefault();
                          this.overrideLanguage(flag);
                        }}
                      >
                        <Image
                          containerClassName={styles.headerFlagContainer}
                          alt={flag}
                          src={require(`images/header/flag_${flag}.svg`)}
                          appStore={this.props.appStore}
                        />
                        {flaglations[i]}
                      </Link>
                    ))}
                  </div>
                </>
              }
            </button>
          </div>
        </div>
        <div className={styles.headerBackgroundTopFixedOffset}/>
        <div className={styles.headerBackgroundTop} ref={this.headerElementRef}>
          <div className={styles.muiContainerFluid}>
            <div className={styles.headerContainer}>
              <div className={styles.headerImageContainer}>
                <Image
                  alt='CMB EB-5 Homepage'
                  src={require('images/header/CMB logo dark for website.png')}
                  appStore={this.props.appStore}
                  linkTo='/'
                />
              </div>
              <div>
                <a className={styles.skipToContentLink} href='#SkipToContentTarget'>Skip To Content</a>
              </div>
              <div className={styles.headerButtonsFlexContainer}>
                <input
                  type='checkbox'
                  id='headerHamburgerInput'
                  className={styles.headerHamburgerInput}
                  checked={this.state.hamburgerMenuChecked}
                  onChange={(): void => this.setState({hamburgerMenuChecked: !this.state.hamburgerMenuChecked})}
                />
                <label className={styles.headerHamburgerToggleContainer} htmlFor={'headerHamburgerInput'}>
                  <div className={styles.headerHamburgerToggle}/>
                </label>
                <nav className={styles.headerButtons}>
                  <HeaderLink
                    page='what-is-eb-5'
                    pageTitle={'WHAT_IS_EB5_PAGE_TITLE'}
                    appStore={this.props.appStore}
                    onClick={(): void => this.setState({hamburgerMenuChecked: false})}
                  />
                  <HeaderLink
                    page='projects'
                    pageTitle={'PROJECTS_PAGE_TITLE'}
                    appStore={this.props.appStore}
                    onClick={(): void => this.setState({hamburgerMenuChecked: false})}
                  />
                  <HeaderLink
                    page='staff'
                    pageTitle={'STAFF_PAGE_TITLE'}
                    appStore={this.props.appStore}
                    onClick={(): void => this.setState({hamburgerMenuChecked: false})}
                  />
                  <HeaderLink
                    page='faq'
                    pageTitle={'FAQ_PAGE_TITLE'}
                    appStore={this.props.appStore}
                    onClick={(): void => this.setState({hamburgerMenuChecked: false})}
                  />
                  <HeaderLink
                    page='news'
                    pageTitle={'NEWS_PAGE_TITLE'}
                    appStore={this.props.appStore}
                    onClick={(): void => this.setState({hamburgerMenuChecked: false})}
                  />
                  {((this.props.appStore.cookies.get('FILESHARE_LOGIN_KEY') as string) || '').length > 0 ? <>
                    <a
                      className={
                        [
                          styles.muiBtn, styles.muiBtnAccent,
                        ].join(' ')
                      }
                      href='/fileshare/view'
                    >
                      <Translatable k='HEADER_MY_FILES'/>
                    </a>
                  </> : <>
                    <a
                      className={
                        [
                          styles.muiBtn, styles.muiBtnAccent,
                        ].join(' ')
                      }
                      href='/fileshare'
                    >
                      <Translatable k='HEADER_LOGIN'/>
                    </a>
                  </>}
                  <Link
                    appStore={this.props.appStore}
                    className={
                      [
                        styles.muiBtn, styles.muiBtnAccent, styles.muiBtnOutline,
                      ].join(' ')
                    }
                    to='/contact'
                    onClick={(): void => this.setState({hamburgerMenuChecked: false})}
                  >
                    <Translatable k='HEADER_CONTACT_US'/>
                  </Link>
                </nav>
              </div>
            </div>
          </div>
          {/*
          <div className={styles.headerUrgentBanner}>
            <Link appStore={this.props.appStore} to='/project/500k-eb-5-investment-opportunity'>
              <h2>
                Act Now! CMB Currently Has A $500K EB-5 Investment Opportunity Available.
                <FontAwesomeIcon
                  icon={faArrowRight}
                />
              </h2>
            </Link>
          </div>
          */}
        </div>
        {isHomepage &&
          <div className={styles.headerLeft}>
            {/* <EB5IsChanging appStore={this.props.appStore}/>*/}
          </div>
        ||
          <>
            {/* <EB5IsChangingPopup appStore={this.props.appStore}/>*/}
          </>
        }
        {isHomepage &&
          <div className={styles.headerContentBackground}>
            <div className={styles.headerSlide}>
              <h2><Translatable k='HEADER_SLIDER_H2'/></h2>
              <p>
                <Translatable k='HEADER_SLIDER_P'/>
              </p>
              <Link
                to='/what-is-eb-5'
                appStore={this.props.appStore}
                className={[styles.muiBtn, styles.muiBtnAccent].join(' ')}
              >
                <Translatable k='HEADER_SLIDER_BUTTON'/>
              </Link>
            </div>
          </div>
        }
        <div id='SkipToContentTarget'/>
        {this.props.children}
      </header>
    );
  }
}

export default withRouter(Header);
