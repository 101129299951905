import * as React from 'react';
import styles from 'components/stylesheets/styles.scss';
import LangStore from 'stores/lang';
import AppStore from 'stores/app';
import {Translatable} from 'components/Translatable';

class FormInput extends React.Component {
  public props: {
    name: string;
    value?: any;
    optional?: boolean;
    hiddenOptional?: boolean;
    latinOnly?: boolean;
    errors?: string[];
    placeholder?: string;
    textarea?: boolean;
    autocomplete?: string;
    triedSubmit?: boolean;
    width: 1 | 2 | 3;
    assign: (name: string, value: any, errors: string[]) => void;

    type?: 'text' | 'password' | 'checkbox';

    langStore: LangStore;
    appStore: AppStore;
    disabled?: boolean;

    children?: React.ReactNode;
  };
  public state: Readonly<{
    used: boolean;

  }>;
  public constructor(props) {
    super(props);
    this.state = {
      used: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.validate = this.validate.bind(this);
  }
  private onBlur(): void {
    this.setState({
      used: true,
    });
    this.props.assign(this.props.name, this.props.value, this.validate());
  }
  private validate(nextValue?: any): string[] {
    if (nextValue === undefined) {
      nextValue = this.props.value || '';
    }
    const errors: string[] = [];
    if (!this.props.optional && nextValue.length < 1) {
      errors.push(`${this.props.name}_UNSET`);
    }
    return errors;
  }
  private onChange(e): void {
    let nextValue = this.props.type === 'checkbox' ? e.target.checked : e.target.value;
    if (this.props.latinOnly) {
      nextValue = nextValue.replace(/[^\u0000-\u024F]+/g, ''); // eslint-disable-line no-control-regex,max-len
    }
    this.props.assign(this.props.name, nextValue, this.validate(nextValue));
  }
  public render(): React.ReactNode {
    const widthClasses = {
      '1': [styles.muiColXs12],
      '2': [styles.muiColXs12, styles.muiColSm6],
      '3': [styles.muiColxs12, styles.muiColSm4],
    };
    let errors: string[] = [];
    if (this.props.errors) {
      errors = this.props.errors.filter((error): boolean => {
        // filter out errors for other components
        return error.indexOf(this.props.name) >= 0;
      });
    }
    const ariaInvalid = (!this.props.optional && (this.props.value||'').length < 0 && true) || errors.length > 0;
    const showErrorMessage = (this.props.triedSubmit || this.state.used) &&
      errors &&
      errors.length > 0;
    return (
      <div className={widthClasses[this.props.width].join(' ')}>
        <label htmlFor={this.props.name} className={styles.contactHelpSection}>
          {this.props.type === 'checkbox' && <>&nbsp;&nbsp;</>}
          <Translatable k={`CONTACT_FORM_${this.props.name}`}/>
          {this.props.optional && !this.props.hiddenOptional && (
            <span className={styles.contactFormOptional}>
              {' '}<Translatable k={`CONTACT_FORM_FIELD_OPTIONAL`}/>
            </span>
          ) || (
            ''
          )
          }
          {this.props.placeholder &&
            <span className={styles.floatRight}>
              (ex: {this.props.placeholder})
            </span>
          }
          {this.props.textarea &&
            <textarea
              id={this.props.name}
              name={this.props.name}
              onChange={this.onChange}
              onBlur={this.onBlur}
              value={this.props.value||''}
              autoComplete={this.props.autocomplete}
              aria-invalid={ariaInvalid}
              disabled={this.props.disabled}
            />
          ||
            <input
              id={this.props.name}
              name={this.props.name}
              onChange={this.onChange}
              onBlur={this.onBlur}
              value={this.props.value||''}
              autoComplete={this.props.autocomplete}
              aria-invalid={ariaInvalid}
              aria-describedby={showErrorMessage && `contactFormErr${this.props.name}` || undefined}
              disabled={this.props.disabled}
              type={this.props.type}
              className={this.props.type === 'checkbox' && styles.contactFormCheckbox || ''}
            />
          }
        </label>
        <div className={styles.contactErrorPlaceholder}>
          {showErrorMessage &&
            <div className={styles.contactError} id={`contactFormErr${this.props.name}`}>
              {errors.map((error, index): React.ReactNode => (
                <React.Fragment key={error}>
                  <Translatable k={`ERROR_${error}`}/>
                  {index < errors.length - 1 &&
                    <br/>
                  }
                </React.Fragment>
              ))}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default FormInput;
