import * as React from 'react';
import {withRouter} from 'react-router-dom';
import {RouteComponentProps} from 'react-router';

import AppStore from 'stores/app';
import LangStore from 'stores/lang';
import { IDocument } from 'lib/IDocument';

interface IScrollToTopProps extends RouteComponentProps<{}> {
  appStore: AppStore;
  langStore: LangStore;
  children?: React.ReactNode;
}

class ScrollToTop extends React.Component<IScrollToTopProps> {
  public props: IScrollToTopProps;
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const doc = document as IDocument;
      const windowOffset =
        (window.pageYOffset || doc.scrollTop || 0) - (doc.clientTop || 0);
      if (windowOffset > this.props.appStore.headerBottom) {
        window.scrollTo(0, Math.round(this.props.appStore.headerBottom));
      } else {
        if(windowOffset + window.innerHeight < this.props.appStore.headerBottom) {
          window.scrollTo(0, Math.round(this.props.appStore.headerBottom));
        };
      }
      setTimeout(() => {
        gtag('send', 'pageview', this.props.location.pathname);
      }, 100)
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);