import * as React from 'react';

import styles from 'components/stylesheets/styles.scss';
import AppStore from 'stores/app';
import LangStore from 'stores/lang';
import ConsultationScheduler from './ConsultationScheduler';
import SchedulerStore from 'stores/scheduler-store';

class SchedulerModal extends React.Component {
  public props: {
    appStore: AppStore;
    langStore: LangStore;
    children?: React.ReactNode;
  };
  public constructor(props) {
    super(props);
  }
  public componentDidMount(): void {
    this.props.appStore.appRef.className = styles.modalOpen;
  }
  public componentWillUnmount(): void {
    this.props.appStore.appRef.className = '';
    SchedulerStore.submitSuccess = false;
    SchedulerStore.showContactForm = false;
  }
  public render(): React.ReactNode {
    return <div className={styles.eb5ChangingPopup}>
      <div className={[styles.dp8, styles.formModal, styles.schedulerModal].join(' ')}>
        <div className={styles.closeButtonContainer}>
          <button
            className={styles.closeModalButton}
            onClick={(): boolean => this.props.appStore.showSchedulerModal = false}
            title='Close'
          >X</button>
        </div>
        <ConsultationScheduler appStore={this.props.appStore} langStore={this.props.langStore}/>
      </div>
    </div>;
  }
}

export default SchedulerModal;
