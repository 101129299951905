import * as React from 'react';

import Reg506c from 'components/page/projects/Reg506c';
import AppStore from 'stores/app';
import LangStore from 'stores/lang';
import styles from 'components/stylesheets/styles.scss';
import ContactForm from 'components/content/ContactForm';

export class StaticProjectsPage83 extends React.Component {
  public props: Readonly<{
    appStore: AppStore;
    langStore: LangStore;
    children?: React.ReactNode;
  }>;
  public render(): JSX.Element {
    return (
      <div className={styles.projectPageNew}>
        <Reg506c appStore={this.props.appStore} langStore={this.props.langStore}>
          <div className={styles.muiContainer}>
            <h1>Group 83 - SoMI</h1>
            <h2>Hayward, California</h2>
            <a
              href='https://www.cmbeb5visa.com/wordpress/wp-content/uploads/2022/08/Group-83-SoMi-Hayward-EMAIL-002.pdf'
              target='_blank' rel='noopener noreferrer'
              className={[styles.muiBtn, styles.muiBtnOutline].join(' ')}
            >
              View full project flyer
            </a>
          </div>
          <br/>
          <br/>
          <img src={require('images/projects/53510933-211202.jpg')} alt='Group 78 - Hillwood California BTS' style={{width: '100%'}}/>
          <div className={styles.projectPageNewStatsSection}>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/Loan-2-1-1.png')} alt=''/>
              <h3>$49.6</h3>
              <p>EB-5 Loan</p>
            </div>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/Loan-1-1.png')} alt=''/>
              <h3>$142.9M</h3>
              <p>Total Project Spending</p>
            </div>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/jobs-1-1.png')} alt=''/>
              <h3>833</h3>
              <p>Estimated Jobs from Development &amp; Contruction</p>
            </div>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/jobs-2-1-1.png')} alt=''/>
              <h3>34.35%</h3>
              <p>Job Creation Buffer</p>
            </div>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/jobs-3-1-1.png')} alt=''/>
              <h3>13.435</h3>
              <p>Jobs Per Investor</p>
            </div>
          </div>
          <div className={styles.muiContainer}>
            <div className={styles.muiRow}>
              <div className={`${styles.muiColXsOffset1} ${styles.muiColXs10}`}>
                <h1 className={[styles.muiTextCenter, styles.transPageHeader].join(' ')}>
                  The Group 83 Project
                </h1>
                <hr/>
              </div>
            </div>
            <h2 className={styles.projectPageNewSubtitle}>A 189-Unit Townhome and Condominium Development</h2>
            <article>
              <div className={styles.muiRow}>
                <div className={[styles.muiColXs12, styles.muiColMd6].join(' ')}>
                  <h3>
                    The Project
                  </h3>
                  <p>
                    CMB Infrastructure Investment Group 83, LP (Group 83) will raise
                    up to $49.6 million of EB-5 investment capital to provide a loan
                    to an affiliate of Hillwood Development Company (Hillwood),
                    a top ten U.S. real estate developer, for the development and
                    construction of the SoMi - Hayward project. The Group 83 Project,
                    is a 189-Unit Townhome and Condominium Development in
                    Hayward, California (the “Project”). The Project is to be constructed
                    on an approximately 12.2 acre (4.937 hectares) site in California’s
                    East San Francisco Bay Area and is located within the city of
                    Hayward, California at the intersection of Mission Boulevard and
                    Tennyson Road.
                  </p>
                  <p>
                    The site was originally purchased by The True Life Companies in
                    2018 and completed an extensive entitlement and permitting
                    process in 2021 allowing for construction to commence. The
                    project will be developed and constructed under a Joint Venture
                    between Hillwood and Homes Built for America. Homes Built for
                    America is the homebuilding division of The True Life Companies.
                  </p>
                  <p>
                    The Project will consist of three and four bedroom townhomes
                    averaging 1,683 square feet and 66 condominiums averaging
                    1,335 square feet. Twenty of the 66 townhomes are anticipated
                    to be developed as affordable and offered for below market
                    rate. The entire development will include appoximately 10,800
                    square feet (1,003 sq meters) of ground-level commercial space,
                    an urban park and a dog park.
                  </p>
                  <p>
                    The development will provide walking distance access to the
                    South Hayward Bay Area Rapid transit (BART) station, and will
                    offer residents convenient access to the various employment
                    opportunities of the greater Silicon Valley/Bay Area. The Project
                    will also offer popular amenities such as community recreation
                    and meeting spaces, over 1.2 acres (0.485 hectares) of outdoor
                    recreation space, and three roof-top decks and certain solarpowered smart home amenities.
                  </p>
                </div>
                <div className={[styles.muiColXs12, styles.muiColMd6].join(' ')}>
                  <br/><br/>
                  <img
                    src={require('images/projects/chrome_2022-06-02_15-22-34.png')}
                    style={{width: '100%', maxWidth: '531px', margin: 'auto', float: 'right'}}
                    alt='Map of USA, california highlighted'
                  />
                </div>
              </div>
            </article>
          </div>
          <div className={styles.projectsPageNewHillwoodSection}>
            <div className={styles.muiContainer}>
              <h3>Hillwood Development Company</h3>
              <div className={styles.projectsPageNewHillwoodSummary}>
                <img src={require('images/projects/Hillwood-Logo-1-1.png')} alt='Hillwood a Perot Company'/>
                <div className={styles.projectsPageNewHillwoodSummaryBar}/>
                <p>
                  Ross Perot, Jr. serves as Chairman of Hillwood, which he founded in 1988. In addition, Mr. Perot
                  is the Chairman of The Perot Group, which manages the various Perot family interests which include
                  real estate, oil and gas and financial investments. Hillwood is ranked among the top ten real
                  estate developers in the United States and is recognized for its high-profile projects and
                  publicprivate ventures.
                </p>
              </div>
              <div className={styles.projectsPageNewHillwoodStats}>
                <div>
                  <h3>34</h3>
                  <span>CMB/Hillwood<br/>Partnership</span>
                </div>
                <div>
                  <h3>28</h3>
                  <span>Partnerships with<br/>I-526 Approvals</span>
                </div>
                <div>
                  <h3>19</h3>
                  <span>Partnerships with<br/>Loan Repayment</span>
                </div>
                <div>
                  <h3>10</h3>
                  <span>Partnerships with<br/>I-829 Approvals</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.muiContainer}>
            <div className={styles.muiRow}>
              <div className={`${styles.muiColXsOffset1} ${styles.muiColXs10}`}>
                <h1 className={[styles.muiTextCenter, styles.transPageHeader].join(' ')}>
                  Project Timeline
                </h1>
                <hr/>
              </div>
            </div>
            <img src={require('images/projects/website project timeline G83.png')} style={{
              maxWidth: '600px',
              display: 'block',
              margin: 'auto',
            }} alt=''/>
            <br/>
          </div>
          <div className={styles.projectsPageNewHillwoodSection}>
            <div className={styles.muiContainer}>
              <div className={styles.muiRow}>
                <div className={`${styles.muiColXsOffset1} ${styles.muiColXs10}`}>
                  <h1 className={[styles.muiTextCenter, styles.transPageHeader].join(' ')}>
                    Reasons to Invest
                  </h1>
                  <hr/>
                </div>
              </div>
              <div className={styles.projectsPageNewReasonsToInvest}>
                <div>
                  <img src={require('images/projects/1-1.png')} alt='1'/>
                  <h3>Leader in EB-5</h3>
                  <p>
                    CMB is the leading EB-5 regional center with a 100% project
                    success rate in over 70 projects.
                  </p>
                </div>
                <div>
                  <img src={require('images/projects/2-1.png')} alt='2'/>
                  <h3>Industry Leading Developer</h3>
                  <p>
                    Hillwood Development Company is an award-winner developer with
                    over 30 years of experience.
                  </p>
                </div>
                <div>
                  <img src={require('images/projects/3-1.png')} alt='3'/>
                  <h3>Homes Are In High Demand</h3>
                  <p>
                    Residential housing in the State of California is in high demand due to a shortage of homes
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.muiContainer}>
            <div className={styles.muiRow}>
              <div className={`${styles.muiColXsOffset1} ${styles.muiColXs10}`}>
                <h1 className={[styles.muiTextCenter, styles.transPageHeader].join(' ')}>
                  Contact Us Today!
                </h1>
                <hr/>
              </div>
            </div>
            <div
              className={[
                styles.muiColXs12,
                styles.muiColMd10,
                styles.muiColMdOffset1,
              ].join(' ')}
            >
              <ContactForm
                appStore={this.props.appStore}
                langStore={this.props.langStore}
                defaults={{
                  MESSAGE: (this.props.langStore.lang[
                    'CONTACT_FORM_CURRENT_PROJECT_DEFAULT_MESSAGE'
                  ].replace(/\$\{project\}/g, 'Group 83 - SoMi Hayward') as string),
                  submitting: false,
                  errors: [''],
                  triedSubmit: false,
                  success: false,
                }}
              />
            </div>
          </div>
        </Reg506c>
      </div>
    );
  }
}

export default StaticProjectsPage83;
