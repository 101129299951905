import {observable} from 'mobx';

export interface IEmployee {
  type: string;
  name: string;
  image: string;
  region: string;
  title: string;
  content?: string;
}

class PageStaffStore {
  @observable dataEmployees: IEmployee[] | false = false;

  @observable post = false;
}


export default PageStaffStore;
