require('./modernizr');
require('raf').polyfill(window);
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import {AsyncComponentProvider} from 'react-async-component';
import asyncBootstrapper from 'react-async-bootstrapper';

import App from 'components/App';
import LangStore from 'stores/lang';
import AppStore from 'stores/app';
import {observable} from 'mobx';

require('es6-promise').polyfill();
require('es6-object-assign/auto');

interface IWindowOverride extends Window {
  apiFetch?: (__req__: any, a: string, b: any) => any;
  ASYNC_COMPONENTS_STATE?: any;
  APP_STORE_STATE?: any;
}

const windowOverride: IWindowOverride = window;

windowOverride.apiFetch = async (__req__, a, b) => {
  // __req__ is isomorphic filler, not used on client side
  const data = await window.fetch(`/api/${a}`, b);
  return await data.json();
};

async function initializeApp() {
  const rehydrateState = windowOverride.ASYNC_COMPONENTS_STATE;

  const appStore = new AppStore();
  Object.assign(appStore, windowOverride.APP_STORE_STATE);
  appStore.initCookieDough(require('cookie-dough')());
  appStore.location = Object.assign({}, window.location); // window.location is a native object, in IE11 it lacks certain normal javascript object methods.

  const checkForWebp = setInterval(() => {
    appStore.webp = document.documentElement ? document.documentElement.className.includes(' webp ') : false;
  }, 15);
  setTimeout(() => {
    clearInterval(checkForWebp);
  }, 1000);

  const langStore = new LangStore();

  langStore.langName = document
    .getElementsByTagName('html')[0]
    .getAttribute('lang') || 'en';

  await langStore.fetchLang();

  const app = (
    <AsyncComponentProvider rehydrateState={rehydrateState}>
      <App appStore={appStore} langStore={langStore}/>
    </AsyncComponentProvider>
  );
  await asyncBootstrapper(app);
  ReactDOM.hydrate(
    app,
    document.getElementById('react-root')
  );
}
initializeApp();
