const stripMethod1 = (content) => {
  const charArr = content.split('');
  const resultArr: string[] = [];
  let htmlZone = 0;
  let quoteZone = 0;
  for (let x = 0; x < charArr.length; x++ ) {
    switch ( charArr[x] + htmlZone + quoteZone ) {
      case '<00': htmlZone = 1; break;
      case '>10': htmlZone = 0; resultArr.push(' '); break;
      case '"10': quoteZone = 1; break;
      case '\'10': quoteZone = 2; break;
      case '"11':
      case '\'12': quoteZone = 0; break;
      default: if (!htmlZone) {
        resultArr.push(charArr[x]);
      }
    }
  }
  return resultArr.join('');
};

export default (content) => {
  content = content.replace(/\n?\r?\n?<br\/>\n?\r?\n?/g, ' ');
  content = content.replace(/ +/g, ' ');
  content = stripMethod1(content);
  return content.replace(/<(?:.|\n)*?>/gm, '').trim();
};
