import * as React from 'react';

import styles from 'components/stylesheets/styles.scss';

export class DataPrivacyToast extends React.Component {
  public state: {
    show: boolean;
  }
  public constructor(props: any) {
    super(props);
    this.state = {
      show: false,
    };
    this.dismiss = this.dismiss.bind(this);
  }
  public componentDidMount(): void {
    if (window.localStorage.getItem('dataPrivacyToast') !== 'true') {
      this.setState({
        show: true,
      });
    }
  }
  public dismiss(): void {
    window.localStorage.setItem('dataPrivacyToast', 'true');
    this.setState({
      show: false,
    });
  }
  public render(): JSX.Element | null {
    if (!this.state.show) return null;
    return (
      <div className={styles.dataPrivacyToast}>
        <div>
          <img src={require('images/header/logo.png')} alt='CMB Logo'/>
          <div>
            <h2>Data Privacy</h2>
            <p>CMB Regional Centers takes your privacy seriously</p>
            <p>
              CMB Regional Centers collects and uses certain personal information, including through the use of cookies.
              Information collected through cookies is used to improve user experience on our site and analyze how it is
              used.
            </p>
            <p>
              California residents have certain rights with regard to the collection and use of their personal
              information.
            </p>
            <p>
              Please see our <a href='/privacy-policy'>Privacy Policy</a> for more information.
            </p>
            <button className={[styles.muiBtn, styles.muiBtnAccent].join(' ')} onClick={this.dismiss}>
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  }
}
