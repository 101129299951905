import * as React from 'react';
import fixWordpressMediaLink from 'lib/fixWordpressMediaLink';
import imageLinkToWebPLink from 'lib/imageLinkToWebPLink';
import * as moment from 'moment';
import _flatten from 'lodash/flatten';

import styles from 'components/stylesheets/styles.scss';
import OEmbed from 'components/content/OEmbed';
import truncatePreserveWord from 'lib/truncatePreserveWord';
import stripHtmlTags from 'lib/stripHtmlTags';
import {IBaseProps} from 'lib/IBasePropTypes';
import ContactForm from './ContactForm';

interface IWordpressPostProps extends IBaseProps {
  post: any;
  preview?: boolean;
  shortPreview?: boolean;
  noTitle?: boolean;
  date?: boolean;
  noArticleTag?: boolean;
  children?: React.ReactNode;
}

class WordpressPost extends React.Component {
  constructor(props) {
    super(props);
  }
  props: IWordpressPostProps;
  processOEmbed(htmlContent) {
    const oembedRegex = /([\s\S]*?)<oembed>(.*?)<\/oembed>([\s\S]*?)$/;
    let m;

    let currentHtmlContentIndex = 0;

    while ((
      m = oembedRegex.exec(htmlContent[currentHtmlContentIndex])
    ) !== null) {
      if (m.index === oembedRegex.lastIndex) {
        oembedRegex.lastIndex++;
      }

      if (
        m[0] !== undefined &&
        m[1] !== undefined &&
        m[2] !== undefined &&
        m[3] !== undefined
      ) {
        const behindExists = currentHtmlContentIndex > 0;
        const aheadExists = currentHtmlContentIndex < htmlContent.length;
        const oEmbedData = JSON.parse(m[2]);
        htmlContent = [
          ...(behindExists ?
            htmlContent.slice(0, currentHtmlContentIndex) :
            []
          ),
          m[1],
          ...(
            this.props.preview ? (
              []
            ) : (
              [
                <OEmbed
                  key='embed'
                  appStore={this.props.appStore}
                  langStore={this.props.langStore}
                  data={oEmbedData}
                />,
              ]
            )
          ),
          m[3],
          ...(aheadExists ?
            htmlContent.slice(currentHtmlContentIndex + 1, htmlContent.length) :
            []
          ),
        ];
        currentHtmlContentIndex = htmlContent.length - 1;
      }
    }

    return htmlContent;
  }
  processLinks(htmlContent) {
    for (let i = 0; i < htmlContent.length; i++) {
      const htmlChunk = htmlContent[i];
      if (typeof htmlChunk === 'string') {
        const fakeDom = document.createElement('html');
        fakeDom.innerHTML = htmlChunk;
        const links = fakeDom.getElementsByTagName('a');
        for (let j = 0; j < links.length; j++) {
          links[j].href = fixWordpressMediaLink(links[j].href);
        }
        htmlContent[i] = fakeDom.getElementsByTagName('body')[0]
          .innerHTML.toString();
      }
    }
    return htmlContent;
  }
  processImages(htmlContent) {
    for (let i = 0; i < htmlContent.length; i++) {
      const htmlChunk = htmlContent[i];
      if (typeof htmlChunk === 'string') {
        const fakeDom = document.createElement('html');
        fakeDom.innerHTML = htmlChunk;
        if (this.props.preview) {
          const images = fakeDom.getElementsByTagName('img');
          const l = images.length;
          for (let i = 0; i < l; i++) {
            images[0].src = '';
            if (images[0].parentNode) {
              images[0].parentNode.removeChild(images[0]);
            }
          }
        } else {
          const imageTags = fakeDom.getElementsByTagName('img');

          for (const imageTag of imageTags) {
            let newSrc = fixWordpressMediaLink(imageTag.src);
            if (this.props.appStore.webp) {
              newSrc = imageLinkToWebPLink(newSrc);
            }
            imageTag.src = newSrc;
          }
        }

        htmlContent[i] = fakeDom.getElementsByTagName('body')[0]
          .innerHTML.toString();
      }
    }
    return htmlContent;
  }
  /*
  processLinks(htmlContent) {
    const linkRegex =
      /([\s\S]*?)<a.*?href=('|")(.+)('|").*?>(.*?)<\/a>([\s\S]*?)$/g;
    let m;

    let currentHtmlContentIndex = 0;

    while ((
      m = linkRegex.exec(htmlContent[currentHtmlContentIndex])
    ) !== null) {
      if (m.index === linkRegex.lastIndex) {
        linkRegex.lastIndex++;
      }

      if (
        m[0] !== undefined &&
        m[1] !== undefined &&
        m[2] !== undefined &&
        m[3] !== undefined &&
        m[4] !== undefined &&
        m[5] !== undefined &&
        m[6] !== undefined
      ) {
        let behindExists = currentHtmlContentIndex > 0;
        let aheadExists = currentHtmlContentIndex < htmlContent.length;
        let to = m[3];
        let label = m[5];
        htmlContent = [
          ...(behindExists ?
            htmlContent.slice(0, currentHtmlContentIndex) :
            []
          ),
          m[1],
          <Link key={to} scrollToTop to={to} appStore={this.props.appStore}>
            {label}
          </Link>,
          m[6],
          ...(aheadExists ?
            htmlContent.slice(currentHtmlContentIndex + 1, htmlContent.length) :
            []
          ),
        ];
        currentHtmlContentIndex = htmlContent.length - 1;
      }
    }

    return htmlContent;
  }
  */
  processParagraphs(htmlContent) {
    return htmlContent.map((htmlChunk, i) => {
      if (typeof(htmlChunk) == 'string') { // Convert text to paragraphs.
        if (i === htmlContent.length - 1) { // Strip trailing whitespace.
          htmlChunk = htmlChunk.replace(/(<br>\s*|&nbsp;\s*)+$/, '');
        }
        if (this.props.preview) {
          if (i === 0) {
            const tmp = document.createElement('DIV');
            tmp.innerHTML = htmlChunk;
            htmlChunk = tmp.textContent || tmp.innerText || '';
          }
        } else {
          htmlChunk = htmlChunk
            .split('\n\n')
            .map((chunk) => `<div>${chunk}</div>`)
            .join('');
        }

        return (
          <div
            key={i}
            dangerouslySetInnerHTML={{__html: htmlChunk}}
          />
        );
      }
      return htmlChunk;
    });
  }
  processContactForms(htmlContent) {
    return _flatten(htmlContent.map((htmlChunk) => {
      if (typeof(htmlChunk) === 'string') {
        const contactFormChunks: React.ReactNode[] = [];
        let foundAny = false;
        const contactChunk = '<div>[[contact-form]]</div>';
        let contactChunkIndex = 0;
        while (htmlChunk.indexOf(contactChunk) >= 0) {
          foundAny = true;
          const chunkIndex = htmlChunk.indexOf(contactChunk);
          contactFormChunks.push(htmlChunk.substr(0, chunkIndex));
          contactFormChunks.push(
            <ContactForm
              key={`contactChunkIndex${contactChunkIndex}`}
              appStore={this.props.appStore}
              langStore={this.props.langStore}
            />
          );
          contactChunkIndex++;
          htmlChunk = htmlChunk.substring(chunkIndex + contactChunk.length);
        }
        contactFormChunks.push(htmlChunk);
        if (!foundAny) {
          return htmlChunk;
        } else {
          return contactFormChunks;
        }
      } else {
        return htmlChunk;
      }
    }));
  }
  render() {
    let htmlContent: any = [];

    if (this.props.preview) {
      htmlContent = (
        <p>
          {
            truncatePreserveWord(
              stripHtmlTags(this.props.post.content.toString()).trim(),
              this.props.shortPreview ? 80 : 160,
              '...'
            )
          }
        </p>
      );
    } else {
      htmlContent.push(this.props.post.content.toString());
      htmlContent = this.processOEmbed(htmlContent);
      htmlContent = this.processImages(htmlContent);
      htmlContent = this.processLinks(htmlContent);
      // htmlContent = this.processLinks(htmlContent);
      // TODO: Fix process links to not create breaklines.
      htmlContent = this.processContactForms(htmlContent);
      htmlContent = this.processParagraphs(htmlContent);
    }

    const ContentContainer = this.props.noArticleTag ?
      (props) => <div className={this.props.className}>{props.children}</div> :
      (props) => <article className={this.props.className}>{props.children}</article>;

    return (
      <ContentContainer>
        {!this.props.preview && !this.props.noTitle &&
          <h1>
            {this.props.post.title}
            {this.props.date &&
              <sub className={styles.newsSubtitle}>
                {moment.utc(this.props.post.date).local().format('LL')}
              </sub>
            }
          </h1>
        }
        {htmlContent}
      </ContentContainer>
    );
  }
}

export default WordpressPost;
