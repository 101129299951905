import {observable} from 'mobx';
import { IPastProject } from 'components/page/admin/projects/AdminPastProjects';

export interface IProjectRow {
  _cmbproject_rows_media_content_id: number;
  _cmbproject_rows_media_content_size?: number;
  _cmbproject_rows_media_content?: string;
  _cmbproject_rows_media_content_caption?: string;
  _cmbproject_rows_content?: string;
}

export interface IProject {
  name: string;
  slug: string;
  type: string;
  id: number;
  boxmedia: string;
  headline: string;
  pdf: string;
  rows: IProjectRow[];
}

class PageProjectsStore {
  @observable projects: IPastProject[] | false = false;

  @observable currentProject: IProject | false = false;
  @observable currentProjects: IProject[] | false = false;

  @observable queuedNextProject: IPastProject | false = false;

  @observable statInvestorEstimate: {value: number} | false = false;
  @observable statJobsEstimate: {value: number} | false = false;
}


export default PageProjectsStore;
