import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons/faHome';
import StructuredData from 'react-google-structured-data';

import Link from 'components/Link';
import LangStore from 'stores/lang';
import AppStore from 'stores/app';

class Breadcrumbs extends React.Component {
  public props: {
    langStore: LangStore;
    appStore: AppStore;
    crumbs: {label: any; path: string}[];
  }
  render() {
    const crumbs = [
      {
        label: this.props.langStore.lang['HOME_PAGE_TITLE'],
        path: '/',
      },
      ...this.props.crumbs,
    ];
    return (
      <span>
        {crumbs.map((crumb, index) => (
          <React.Fragment key={crumb.path}>
            <Link to={crumb.path} appStore={this.props.appStore} style={{fontWeight: 400}}>
              {index === 0 && // Lead with home button
                <>
                  <FontAwesomeIcon
                    icon={faHome}
                  />
                  &nbsp;
                </>
              }
              {crumb.label}
            </Link>
            {' > '}
          </React.Fragment>
        ))}
        {this.props.langStore.lang['BREADCRUMBS_HERE']}
        <StructuredData type='BreadcrumbList'
          data={{
            itemListElement: [
              ...crumbs.map((crumb, index) => ({
                '@type': 'ListItem',
                'position': index + 1,
                'name': crumb.label,
                'item': `${CONFIG.origin}${crumb.path.startsWith('/') ? '' : '/'}${crumb.path}`,
              })),
              {
                '@type': 'ListItem',
                'position': crumbs.length + 1,
                'name': this.props.langStore.lang['BREADCRUMBS_HERE'],
                'item': `${CONFIG.origin}${this.props.appStore.location.href}`,
              },
            ],
          }}
        />
      </span>
    );
  }
}

export default Breadcrumbs;
