import * as React from 'react';

import Reg506c from 'components/page/projects/Reg506c';
import AppStore from 'stores/app';
import LangStore from 'stores/lang';
import styles from 'components/stylesheets/styles.scss';
import ContactForm from 'components/content/ContactForm';

export class StaticProjectsPage81 extends React.Component {
  public props: Readonly<{
    appStore: AppStore;
    langStore: LangStore;
    children?: React.ReactNode;
  }>;
  public render(): JSX.Element {
    return (
      <div className={styles.projectPageNew}>
        <Reg506c appStore={this.props.appStore} langStore={this.props.langStore}>
          <div className={styles.muiContainer}>
            <h1>Group 81 - Inland Empire Industrial</h1>
            <h2>San Bernardino, California</h2>
            <a
              href='https://www.cmbeb5visa.com/wordpress/wp-content/uploads/2022/09/G81-Inward-Empire-Industrial-Flyer-EMAIL.pdf'
              target='_blank' rel='noopener noreferrer'
              className={[styles.muiBtn, styles.muiBtnOutline].join(' ')}
            >
              View full project flyer
            </a>
          </div>
          <br/>
          <br/>
          <img src={require('images/projects/g81square.jpg')} alt='Group 81 - Inland Empire Industrial' style={{width: '100%'}}/>
          <div className={styles.projectPageNewStatsSection}>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/Loan-2-1-1.png')} alt=''/>
              <h3>$68</h3>
              <p>EB-5 Loan</p>
            </div>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/Loan-1-1.png')} alt=''/>
              <h3>$261M</h3>
              <p>Total Project Spending</p>
            </div>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/jobs-1-1.png')} alt=''/>
              <h3>1,087</h3>
              <p>Estimated Jobs from Development &amp; Contruction</p>
            </div>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/jobs-2-1-1.png')} alt=''/>
              <h3>27.882%</h3>
              <p>Job Creation Buffer</p>
            </div>
            <div className={styles.projectPageNewStatsSectionItem}>
              <img src={require('images/projects/jobs-3-1-1.png')} alt=''/>
              <h3>12.788</h3>
              <p>Jobs Per Investor</p>
            </div>
          </div>
          <div className={styles.muiContainer}>
            <div className={styles.muiRow}>
              <div className={`${styles.muiColXsOffset1} ${styles.muiColXs10}`}>
                <h1 className={[styles.muiTextCenter, styles.transPageHeader].join(' ')}>
                  The Group 81 Project
                </h1>
                <hr/>
              </div>
            </div>
            <h2 className={styles.projectPageNewSubtitle}>Three separate logistics facility subprojects</h2>
            <article>
              <div className={styles.muiRow}>
                <div className={[styles.muiColXs12, styles.muiColMd6].join(' ')}>
                  <h3>
                    GWS7
                  </h3>
                  <p>
                    GWS7 will be constructed on an approximately 9.55 acres
                    strategically located in Hillwood’s AllianceCalifornia Business
                    Park. AllianceCalifornia is strategically located in close
                    proximity to the Ports of Los Angeles and Long Beach, and 2
                    miles from the BNSF Intermodal rail yard.
                  </p>
                  <p>
                    The Project will consist of a +/- 221,436 square foot Class-A
                    speculative logistics facility. Hillwood will purchase the land,
                    perform the site work, and develop the Project. Hillwood is
                    underwriting a 9 month lease-up schedule, 3 months of free
                    rent, and is seeking a 5 year lease term.
                  </p>
                  <h3>
                    GWS8
                  </h3>
                  <p>
                    GWS8 will be constructed on an approximately 15.25 acres
                    strategically located in Hillwood’s AllianceCalifornia Business
                    Park. AllianceCalifornia is strategically located in close
                    proximity to the Ports of Los Angeles and Long Beach, and 2
                    miles from the BNSF Intermodal rail yard.
                  </p>
                  <p>
                    The Project will consist of a +/- 300,926 square foot Class-A
                    speculative logistics facility. Hillwood owns a portion of the
                    Project site and will acquire the remaining land, perform the
                    site work, and develop the Project. Hillwood is underwriting
                    a 9 month lease-up schedule, 3 months of free rent, and is
                    seeking a 5 year lease term.
                  </p>
                  <h3>NAPA</h3>
                  <p>
                    Napa will be constructed on an approximately 35.4 acres
                    located on Napa Street in Rancho Cucamonga, California in
                    the West Inland Empire. The Project is strategically located
                    at the intersection of Interstate 10 and 15, and is in close
                    proximity to the Ports of Los Angeles and Long Beach and
                    is approximately eleven miles from the BNSF Intermodal rail
                    yard.
                  </p>
                  <p>
                    The Project will consist of 2 logistics facilities, +/- 500,648
                    square foot Class-A speculative logistics facility and a +/-
                    155,230 square foot Class-A logistics facility. Hillwood will
                    purchase the land in one phase, perform the site work, and
                    develop the Project. Hillwood is underwriting a 12 month
                    lease-up schedule and is seeking a 7 year lease term.
                  </p>
                </div>
                <div className={[styles.muiColXs12, styles.muiColMd6].join(' ')}>
                  <br/><br/>
                  <img
                    src={require('images/projects/Vector US Map San Bernardino CA-03.png')}
                    style={{width: '100%', maxWidth: '531px', margin: 'auto', float: 'right'}}
                    alt='Map of USA, san bernardino CA highlighted'
                  />
                </div>
              </div>
            </article>
          </div>
          <div className={styles.projectsPageNewHillwoodSection}>
            <div className={styles.muiContainer}>
              <h3>Hillwood Development Company</h3>
              <div className={styles.projectsPageNewHillwoodSummary}>
                <img src={require('images/projects/Hillwood-Logo-1-1.png')} alt='Hillwood a Perot Company'/>
                <div className={styles.projectsPageNewHillwoodSummaryBar}/>
                <p>
                  Ross Perot, Jr. serves as Chairman of Hillwood, which he founded in 1988. In addition, Mr. Perot
                  is the Chairman of The Perot Group, which manages the various Perot family interests which include
                  real estate, oil and gas and financial investments. Hillwood is ranked among the top ten real
                  estate developers in the United States and is recognized for its high-profile projects and
                  publicprivate ventures.
                </p>
              </div>
              <div className={styles.projectsPageNewHillwoodStats}>
                <div>
                  <h3>35</h3>
                  <span>CMB/Hillwood<br/>Partnership</span>
                </div>
                <div>
                  <h3>28</h3>
                  <span>Partnerships with<br/>I-526 Approvals</span>
                </div>
                <div>
                  <h3>19</h3>
                  <span>Partnerships with<br/>Loan Repayment</span>
                </div>
                <div>
                  <h3>10</h3>
                  <span>Partnerships with<br/>I-829 Approvals</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.muiContainer}>
            <div className={styles.muiRow}>
              <div className={`${styles.muiColXsOffset1} ${styles.muiColXs10}`}>
                <h1 className={[styles.muiTextCenter, styles.transPageHeader].join(' ')}>
                  Project Timeline
                </h1>
                <hr/>
              </div>
            </div>
            <img src={require('images/projects/Group 81 Website Project Timeline.png')} style={{
              display: 'block',
              margin: 'auto',
            }} alt=''/>
            <br/>
          </div>
          <div className={styles.projectsPageNewHillwoodSection}>
            <div className={styles.muiContainer}>
              <div className={styles.muiRow}>
                <div className={`${styles.muiColXsOffset1} ${styles.muiColXs10}`}>
                  <h1 className={[styles.muiTextCenter, styles.transPageHeader].join(' ')}>
                    Reasons to Invest
                  </h1>
                  <hr/>
                </div>
              </div>
              <div className={styles.projectsPageNewReasonsToInvest}>
                <div>
                  <img src={require('images/projects/1-1.png')} alt='1'/>
                  <h3>Leader in EB-5</h3>
                  <p>
                    CMB is the leading EB-5 regional center with a 100% project
                    success rate in over 70 projects.
                  </p>
                </div>
                <div>
                  <img src={require('images/projects/2-1.png')} alt='2'/>
                  <h3>Industry Leading Developer</h3>
                  <p>
                    Hillwood Development Company is an award-winner developer with
                    over 30 years of experience.
                  </p>
                </div>
                <div>
                  <img src={require('images/projects/3-1.png')} alt='3'/>
                  <h3>E-commerce Is On The Rise</h3>
                  <p>
                    Distribution and warehousing facilities are in growing demand
                    due to the massive growth in e-commerce.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.muiContainer}>
            <div className={styles.muiRow}>
              <div className={`${styles.muiColXsOffset1} ${styles.muiColXs10}`}>
                <h1 className={[styles.muiTextCenter, styles.transPageHeader].join(' ')}>
                  Contact Us Today!
                </h1>
                <hr/>
              </div>
            </div>
            <div
              className={[
                styles.muiColXs12,
                styles.muiColMd10,
                styles.muiColMdOffset1,
              ].join(' ')}
            >
              <ContactForm
                appStore={this.props.appStore}
                langStore={this.props.langStore}
                defaults={{
                  MESSAGE: (this.props.langStore.lang[
                    'CONTACT_FORM_CURRENT_PROJECT_DEFAULT_MESSAGE'
                  ].replace(/\$\{project\}/g, 'Group 81 - Inland Empire Industrial') as string),
                  submitting: false,
                  errors: [''],
                  triedSubmit: false,
                  success: false,
                }}
              />
            </div>
          </div>
        </Reg506c>
      </div>
    );
  }
}

export default StaticProjectsPage81;
